import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useActiveWeb3React } from '../../hooks'

import { useWalletModalToggle } from '../../state/application/hooks'
import { isAddress, calculateGasMargin } from '../../utils'
import styled from 'styled-components'
import { Collapse } from 'react-collapse'
import ToolTip from '../ToolTip'

import { useAlert } from 'react-alert'
import { ChromePicker } from 'react-color'
import CloseIMG from '../../assets/images/close-btn.png'
import InputRange from 'react-input-range'
import Media from '../../theme/media-breackpoint'
import { useTokenName } from '../../data/TokenName'
import TextBox from '../TextBoxNumaric'
import { API_BASEURL } from '../../constants'
import { ACTIVE_NETWORK } from '../../connectors'
import { usePDOLicensesContract } from '../../hooks/useContract'
import TransactionConfirmationModal from '../TransactionConfirmationModal'
import { BigNumber } from 'bignumber.js'
import { parseUnits } from '@ethersproject/units'
import { ZERO_ADDRESS } from '../../constants/pdo'
import axios from 'axios'
import { Redirect } from 'react-router-dom'

import useOutsideClick from '../../hooks/useOutsideClick'
import TermsAndConditions from '../TermsAndConditions'

const PERCENT_DENOMINATOR = 100

const api = axios.create({
  baseURL: API_BASEURL
})

const getTokenAmountInWei = (amount: number, power: number | undefined) => {
  if (power === undefined) {
    return 0
  }
  return parseUnits(amount.toString(), power).toString()
}

const getPriceAmount = (amount: number) => {
  if (amount === 0) {
    return 0
  }
  return parseUnits(amount.toString(), 9).toString()
}

const getPercentAmount = (amount: number) => {
  if (amount === 0) {
    return 0
  }
  return new BigNumber(amount).multipliedBy(PERCENT_DENOMINATOR).toString()
}

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export default function PDOLicence() {
  const { account, chainId } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const alert = useAlert()

  const fontMenu = useRef()
  const secondaryColorRef = useRef()
  const primaryColorRef = useRef()
  const [startMark, setStartMark] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')

  const [fee, setFee] = useState<number>(0)
  const [isExtrafee, setIsExtraFee] = useState<boolean>(false)
  const [isReimburse, setIsReimburse] = useState<boolean>(false)
  const [isFnt, setIsFnt] = useState<boolean>(false)
  const [tokenAddress, setTokenaddress] = useState('')
  const [routerAddress, setRouterAddress] = useState('')
  const [reDays, setReDays] = useState<number>(0)
  const [reRatio, setReRatio] = useState<number>(0)
  const [optionCount, setOptionCount] = useState([])
  const [amountUsd, setAmountUsd] = useState<number[]>([0])
  const [extrafee, setExtraFee] = useState<number[]>([0])

  const [domainName, setDomainName] = useState('')
  const [pdoDisplay, setPdoDisplay] = useState('SINGLE')
  const [isNativeOnTop, setIsNativeOnTop] = useState<boolean>(false)
  const [redirect, setRedirect] = useState<boolean>(false)

  const [isSubdomain, setIsSubdomain] = useState(false)
  const [fontCollpase, setFontCollpase] = useState(false)
  const [showLicenseDetails, setShowLicenseDetails] = useState(false)
  const [logoImage, setLogoImage] = useState<any>('')
  const [bgimage, setBgimage] = useState<any>('')
  const [logoUrl, setLogoUrl] = useState('')
  const [fontStyle, setFontStyle] = useState('Default')
  const [primaryColors, setPrimaryColors] = useState('#fcf302')
  const [seconderyColor, setSeconderyColor] = useState('#14b745')
  // const [swapButton, setSwapButtonColor] = useState("#91dc27");
  const [showPrimaryColor, setShowPrimaryColor] = useState(false)
  const [showSecondaryColor, setShowSecondaryColor] = useState(false)
  const [bscLicenseAddress, setBscLicenseAddress] = useState(ZERO_ADDRESS)
  const [bscLicenseFees, setBscLicenseFees] = useState(0)
  const [affiliateFee, setAffiliateFee] = useState<number>(0)
  // const [showSwapButtonColor, setShowSwapButtonColor] = useState(false);

  const [isChecked, setIsChecked] = useState(false)

  //Handle Checkbox
  const handleCheckBox = () => {
    setIsChecked(prevState => !prevState)
  }

  const [isTermsVisible, setTermsVisible] = useState(false)
  const closePopUp = flag => {
    setTermsVisible(flag)
  }

  const PDOLicensesContract = usePDOLicensesContract()

  const feeSetter = (amount: any) => {
    if (amount < 250) {
      setIsExtraFee(false)
      setIsFnt(false)
    }
    setFee(amount)
  }

  const affiliateFeeSetter = (amount: any) => {
    setAffiliateFee(amount)
  }

  // Creating state of IP and TimeStamp when user creates a license
  const [userDetails, setUserDetails] = useState({ ip: '', timeStamp: '' })

  //creating function to load ip address from the API
  const getUserDetails = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    const moment = require('moment')
    const timeStamp = moment().format('h:mm:ss a')
    userDetails.ip = res?.data?.IPv4
    userDetails.timeStamp = timeStamp
    setUserDetails({ ...userDetails })
  }

  useEffect(() => {}, [domainName])

  const handleNativeToken = (e: any) => {
    if (isAddress(e.target.value)) setTokenaddress(e.target.value)
  }
  const handleAmmRouter = (e: any) => {
    if (isAddress(e.target.value)) setRouterAddress(e.target.value)
  }
  const handleReDays = (e: number) => setReDays(e)
  const handleReRatio = (e: number) => setReRatio(e)
  const handleDomain = event => setDomainName(event.target.value)
  const handleExtraFee = (index: number, value: number) =>
    setExtraFee([...extrafee.slice(0, index), Number(value), ...extrafee.slice(index + 1)])
  const handleAmountUsd = (index: number, value: number) =>
    setAmountUsd([...amountUsd.slice(0, index), Number(value), ...amountUsd.slice(index + 1)])

  const changeLogo = async (event: any) => {
    // console.log(event.target.files[0])
    const file = event.target.files[0]
    const image: any = await toBase64(file)
    setLogoImage(image)
    // checkAspectRatio(file).then(() => setLogoImage(image))
  }

  const changeBg = async e => {
    const file = e.target.files[0]
    const image: any = await toBase64(file)
    setBgimage(image)
  }

  const changeurl = event => setLogoUrl(event.target.value)
  const changeFontStyle = event => setFontStyle(event.target.value)

  const toggalColor = (e, id) => {
    // e.stopPropagation();
    // document.getElementById(id).checked = true;
  }

  const closeChromePickerPopUp = e => {
    e.stopPropagation()
    setShowPrimaryColor(false)
    setShowSecondaryColor(false)
    // setShowSwapButtonColor(false);
  }

  function isDescending(arr: any) {
    return arr.every(function(x: any, i: any) {
      return i === 0 || x <= arr[i - 1]
    })
  }

  function isAscending(arr: any) {
    return arr.every(function(x: any, i: any) {
      return i === 0 || x >= arr[i - 1]
    })
  }

  useOutsideClick(fontMenu, () => {
    setFontCollpase(false)
  })

  useOutsideClick(primaryColorRef, () => {
    setShowPrimaryColor(false)
  })

  useOutsideClick(secondaryColorRef, () => {
    setShowSecondaryColor(false)
  })

  //Check Aspect Ratio of uploaded logo
  const checkAspectRatio = value => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(value)
      reader.onload = function(value) {
        const img = new Image()
        img.src = value.target.result as string
        img.onload = function() {
          const aspectRatio = img.width / img.height
          if (aspectRatio === 4 / 1) {
            resolve(aspectRatio)
          } else {
            alert.show('Please Upload 4/1 ratio image')
            return
          }
        }
      }
      reader.onerror = error => reject(error)
    })
  }

  const checkAndValidateAndDeploy = () => {
    if (!isChecked) {
      alert.show('PLEASE AGREE TO THE TERM AND CONDITIONS OF LICENSING USE')
      return
    }
    if (!isDescending(amountUsd)) {
      alert.show('Up front fix launch cost should be in Descending Order')
      return
    }
    if (!isAscending(extrafee)) {
      alert.show('Fundraising success fee should be in Ascending Order')
      return
    }
    if (domainName === '') {
      alert.show('Please enter Domain Name')
      return
    }
    try {
      new URL(logoUrl)
    } catch (_) {
      alert.show('Please enter valid logo url')
      return
    }
    if (logoImage === '') {
      alert.show('Please upload logo')
      return
    }
    if (bgimage === '') {
      alert.show('Please background image')
      return
    }
    if (isReimburse) {
      if (tokenAddress === '') {
        alert.show('Please enter Token Address')
        return
      }
      if (routerAddress === '') {
        alert.show('Please enter Router Address')
        return
      }
    }

    api.get(`/getDomain/${domainName}`, {}).then(response => {
      if (response.data.data === null) {
        createLicense()
        getUserDetails().then(() => {
          console.log(userDetails)
        })
      } else {
        alert.show('subdomain name already taken')
      }
    })
  }

  const addOption = () => {
    setOptionCount([...optionCount, { id: optionCount.length + 1 }])
    setAmountUsd([...amountUsd, 0])
    setExtraFee([...extrafee, 0])
  }

  const removeOption = (index: number) => {
    setOptionCount([...optionCount.slice(0, index), ...optionCount.slice(index + 1)])
    setAmountUsd([...amountUsd.slice(0, index + 1), ...amountUsd.slice(index + 2)])
    setExtraFee([...extrafee.slice(0, index + 1), ...extrafee.slice(index + 2)])
  }

  const handleDismissConfirmation = useCallback(() => {
    if (txHash !== '') {
      return
    }
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
  }, [])

  const txCreated = (txHash: string) => {
    api
      .post(`/createLicense`, {
        txHash: txHash,
        domain: domainName,
        pdoDisplay: pdoDisplay,
        isNativeOnTop: isNativeOnTop,
        backGroundImage: bgimage,
        primaryColors: primaryColors,
        seconderyColor: seconderyColor,
        logo: logoImage,
        fontStyle: fontStyle,
        logoUrl: logoUrl
      })
      .then(response => {
        console.log('/createLicense api response', response)
        console.log('License entry added')
        // setShowLicenseDetails(true)
      })
  }

  const fetchLicense = async txHash => {
    api.get(`/getLicenses/` + txHash).then(response => {
      if (response.data.resp_code === 1) {
        console.log(response.data.data.reimbursementVault)
        setBscLicenseAddress(response.data.data.reimbursementVault)
        setBscLicenseFees(response.data.data.projectFee)
        setShowLicenseDetails(true)
      }
    })
  }

  const createLicense = async () => {
    const tmpAmountUsd = []
    const tmpExtrafee = []

    for (let index = 0; index < amountUsd.length; index++) {
      tmpAmountUsd.push(getPriceAmount(amountUsd[index]))
      tmpExtrafee.push(getPercentAmount(extrafee[index]))
    }

    const contractValue = {
      nativeToken: isReimburse ? tokenAddress : ZERO_ADDRESS,
      reimbursementVault: ZERO_ADDRESS,
      isReimbursement: isReimburse,
      MLR: '0',
      isFNT: isFnt,
      projectFee: fee,
      amountUsd: tmpAmountUsd,
      fee: tmpExtrafee,
      routerAddress: isReimburse ? routerAddress : ZERO_ADDRESS,
      poolAddress: ZERO_ADDRESS,
      owner: account
    }

    const tempRedays = reDays * 86400
    const tempReRatio = reRatio * 100
    const affiliateFeeRatio = affiliateFee * 100
    let onError = false
    if (PDOLicensesContract !== null) {
      setShowConfirm(true)
      try {
        const estimatedGas = await PDOLicensesContract.estimateGas.createLicense(
          contractValue,
          tempRedays,
          tempReRatio,
          affiliateFeeRatio,
          { value: 0 }
        )

        const txResponse = await PDOLicensesContract.createLicense(
          contractValue,
          tempRedays,
          tempReRatio,
          affiliateFeeRatio,
          {
            gasLimit: calculateGasMargin(estimatedGas),
            value: '0'
          }
        ).catch(() => {
          onError = true
          setShowConfirm(false)
        })
        if (onError === false) {
          setTxHash(txResponse.hash)
          txCreated(txResponse.hash)
          const txReceipt = await txResponse.wait()

          if (txReceipt.status === 1) {
            setTimeout(() => {
              setShowConfirm(false)
              fetchLicense(txResponse.hash)
              alert.show('Lisance created succsfully')
            }, 5000)
          } else {
            alert.show('Some error occur in creating pdo')
            setShowConfirm(false)
          }
        }
      } catch (error) {
        console.log(error)
        onError = true
        setShowConfirm(false)
        // alert.show(error.data.message)
      }
    }
  }

  const name = useTokenName(tokenAddress)

  const previewCalled = () => {
    localStorage.setItem('backGroundImage', bgimage)
    localStorage.setItem('primaryColors', primaryColors)
    localStorage.setItem('seconderyColor', seconderyColor)
    localStorage.setItem('logo', logoImage)
    localStorage.setItem('fontStyle', fontStyle)
    window.open('/#/list?preview=true', '_blank').focus()
  }

  if (redirect) {
    return <Redirect to="/list"></Redirect>
  }

  return (
    <>
      {isTermsVisible && <TermsAndConditions isOpen={isTermsVisible} setIsOpen={closePopUp}></TermsAndConditions>}
      <TransactionConfirmationModal
        isOpen={showConfirm}
        startMark={startMark}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={txHash === '' ? true : false}
        hash={txHash}
        content={() => <></>}
        pendingText="Waiting for Confirmation"
      />

      <MainPOPBX>
        <div className="mainBlock">
          {/* <!--======================= SWAP BLOCK START =====================--> */}
          <div className="swap-Block">
            <div className="container-Grid">
              <div className="smeTitle01-v2">Get a PDO Pad License</div>

              <div className="smeText01">
                <p>
                  The PDO pad license includes a PDO pad clone, <a href="#">SmartSwap</a> integration allowing BNB and
                  ETH investment, the <a href="#">Dumper Shield</a> with built in <a href="#">OTC</a> plus a safe{' '}
                  <a href="#">Gateway</a> to the secondary market, and <a href="#">Reverse KYC / AML</a>.
                </p>

                <p>
                  PDO charges a 2.5% directly from the public but reimburses them 100% with PDO tokens. Plus 2.5% from
                  the project. If you become a licensee (which is free) then we'll reward you with the 2.5% of the total
                  deposits that occurred on your PDO pad.
                </p>
              </div>
            </div>
            <div className="swap-textBox">
              <div className="container-Grid">
                {!showLicenseDetails && (
                  <>
                    <div className="gwFormMbox">
                      <div className="gwFormLMbox">
                        <div className="gwFormSTitle01">
                          <span>1</span> Set up your fees and reimbursement
                        </div>

                        <div className="gwFormSbox01">
                          <div className="gwFormTitle01">
                            How much do you want us to charge your PDOs
                            <ToolTip tip={'Coming Soon'} id={'m2'} />
                          </div>
                          <div className="dragorInput v2">
                            <InputRange
                              maxValue={250}
                              minValue={0}
                              value={fee}
                              formatLabel={value => `${value / 100}%`}
                              onChange={value => feeSetter(value)}
                            />
                          </div>
                        </div>

                        <div className="gwFormSbox01">
                          <div className="gwFormTitle01">
                            How much do you want give to Affiliate From {fee / 100}% Fee?
                            <ToolTip tip={'Coming Soon'} id={'m2'} />
                          </div>
                          <div className="dragorInput v2">
                            <InputRange
                              maxValue={100}
                              minValue={0}
                              value={affiliateFee}
                              formatLabel={value => `${value}%`}
                              onChange={value => affiliateFeeSetter(value)}
                            />
                          </div>
                        </div>

                        <div className="gwFormSbox01">
                          <div className="gwFormTitle01">
                            Do you want to charge your PDOs extra fees besides the 2.5% ?
                          </div>
                          <div className="gwFormSFormbox02">
                            <div className="md-radio md-radio-inline w50">
                              <input
                                type="radio"
                                id="radio145"
                                checked={isExtrafee}
                                onClick={() => {
                                  setIsExtraFee(true)
                                  setFee(250)
                                }}
                                name="stepin56"
                                value="option146"
                              />
                              <label htmlFor="radio145">
                                Yes{' '}
                                <ToolTip
                                  tip={
                                    'You can choose to charge your OPO any amount that you want above the 2.5% as long that your reimbursed your PDOs with your native token'
                                  }
                                  id={'m2'}
                                />
                              </label>
                            </div>
                            <div className="md-radio md-radio-inline w50">
                              <input
                                type="radio"
                                id="radio146"
                                checked={!isExtrafee}
                                onClick={() => {
                                  setIsExtraFee(false)
                                  setIsFnt(false)
                                }}
                                name="stepin56"
                                value="option145"
                              />
                              <label htmlFor="radio146">No</label>
                            </div>
                          </div>
                        </div>

                        {isExtrafee && (
                          <>
                            <div className="gwFormSbox01">
                              <div className="gwFormTitle01">
                                Create payment options for your PDO projects to choose from{' '}
                                <ToolTip tip={'Coming Soon'} id={'m7'} />
                              </div>
                            </div>

                            <GWMTitle01>Option 1</GWMTitle01>

                            <OptMBX style={{ marginBottom: '10px' }}>
                              <div className="optTitle01">
                                Remove fee if paired with {name !== undefined ? name : '[TOKEN]'}
                              </div>
                              <div className="gwFormSFormbox02" style={{ width: '100%' }}>
                                <div className="md-radio md-radio-inline w50">
                                  <input
                                    type="radio"
                                    id="radiof11"
                                    name="stepin99"
                                    value="option146"
                                    checked={isFnt}
                                    onClick={() => {
                                      setIsFnt(true)
                                    }}
                                  />
                                  <label htmlFor="radiof11">Yes</label>
                                </div>
                                <div className="md-radio md-radio-inline w50">
                                  <input
                                    type="radio"
                                    id="radiof12"
                                    name="stepin99"
                                    value="option145"
                                    checked={!isFnt}
                                    onClick={() => {
                                      setIsFnt(false)
                                    }}
                                  />
                                  <label htmlFor="radiof12">No</label>
                                </div>
                              </div>
                              <div className="gwFormSbox01 NpDisplyFix02 ">
                                <div className="optSbx01">
                                  Up front fix launch cost
                                  <div className="gwFormSFormbox01 v3 smFixer03">
                                    <span>$</span>
                                    <TextBox
                                      value={amountUsd[0]}
                                      onUserInput={e => handleAmountUsd(0, e)}
                                      placeholder={''}
                                    />
                                  </div>
                                </div>

                                <div className="optSbx02" style={{ fontSize: '22px', paddingLeft: '20px' }}>
                                  {' '}
                                  <i className="fas fa-plus"></i>{' '}
                                </div>

                                <div className="optSbx01">
                                  Fundraising success fee
                                  <div className="gwFormSFormbox01 v3 smFixer03">
                                    <TextBox
                                      value={Number(extrafee[0])}
                                      onUserInput={e => handleExtraFee(0, e)}
                                      placeholder={''}
                                    />
                                    <span>%</span>
                                  </div>
                                </div>
                              </div>
                            </OptMBX>

                            {optionCount.map((row, i) => (
                              <>
                                <GWMTitle01>Option {i + 2}</GWMTitle01>
                                <OptMBX style={{ marginBottom: '10px' }}>
                                  <button className="closeBtn" onClick={() => removeOption(i)}>
                                    {' '}
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                  </button>
                                  <div className="gwFormSbox01 NpDisplyFix02 ">
                                    <div className="optSbx01">
                                      Up front fix launch cost
                                      <div className="gwFormSFormbox01 v3 smFixer03">
                                        <span>$</span>
                                        <TextBox
                                          value={Number(amountUsd[i + 1])}
                                          onUserInput={e => handleAmountUsd(i + 1, e)}
                                          placeholder={''}
                                        />
                                      </div>
                                    </div>

                                    <div className="optSbx02" style={{ fontSize: '22px', paddingLeft: '20px' }}>
                                      {' '}
                                      <i className="fas fa-plus"></i>{' '}
                                    </div>

                                    <div className="optSbx01">
                                      Fundraising success fee
                                      <div className="gwFormSFormbox01 v3 smFixer03">
                                        <TextBox
                                          value={Number(extrafee[i + 1])}
                                          onUserInput={e => handleExtraFee(i + 1, e)}
                                          placeholder={''}
                                        />
                                        <span>%</span>
                                      </div>
                                    </div>
                                  </div>
                                </OptMBX>
                              </>
                            ))}

                            <div className="colorPlateMBox">
                              <div className="colorPlateTitle01">
                                <button onClick={addOption} style={{ fontSize: '14px' }}>
                                  Add more options
                                </button>
                              </div>
                            </div>
                          </>
                        )}

                        {isExtrafee && (
                          <div className="gwFormSbox01">
                            <div className="gwFormTitle01">Do you want to reimburse PDOs extra fee ?</div>
                            <div className="gwFormSFormbox02">
                              <div className="md-radio md-radio-inline w50">
                                <input
                                  type="radio"
                                  id="radio147"
                                  checked={isReimburse}
                                  onClick={() => {
                                    setIsReimburse(true)
                                  }}
                                  name="stepin57"
                                  value="option146"
                                />
                                <label htmlFor="radio147">Yes </label>
                              </div>
                              <div className="md-radio md-radio-inline w50">
                                <input
                                  type="radio"
                                  id="radio148"
                                  checked={!isReimburse}
                                  onClick={() => {
                                    setIsReimburse(false)
                                  }}
                                  name="stepin57"
                                  value="option145"
                                />
                                <label htmlFor="radio148">No</label>
                              </div>
                            </div>
                          </div>
                        )}

                        {isReimburse && (
                          <OptMBX>
                            <div className="optTitle01 v2">BSC</div>
                            <div className="gwFormSbox01" style={{ marginTop: '40px' }}>
                              <div className="gwFormTitle01">Add your native token smart contract on BSC </div>
                              <div className="gwFormSFormbox01 smFixer03">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  autoCorrect="off"
                                  minLength={1}
                                  maxLength={79}
                                  spellCheck="false"
                                  onChange={handleNativeToken}
                                />{' '}
                                <span>{name !== undefined ? name : 'TOKEN'}</span>
                              </div>
                            </div>

                            <div className="gwFormSbox01 ">
                              <div className="gwFormTitle01">
                                Add a Router contract Address for AMM For your pair
                                <ToolTip tip={'Coming Soon'} id={'m4'} />
                              </div>
                              <div className="gwFormSFormbox01 smFixer03">
                                <input type="text" onChange={handleAmmRouter} />
                              </div>
                            </div>

                            <div className="gwFormSbox01">
                              <div className="gwFormTitle01">
                                How many days users need to stake to get 100% reimbursement?{' '}
                                <ToolTip tip={'Coming Soon'} id={'m4'} />
                              </div>
                              <div className="gwFormSFormbox01 smFixer03">
                                <TextBox value={reDays} onUserInput={handleReDays} placeholder={''} />
                              </div>
                            </div>

                            <div className="gwFormSbox01">
                              <div className="gwFormTitle01">
                                Choose the token ratio users will need to stake{' '}
                                <ToolTip tip={'Coming Soon'} id={'m4'} />
                              </div>
                              <div className="gwFormSFormbox01 smFixer03">
                                <TextBox value={reRatio} onUserInput={handleReRatio} placeholder={''} />{' '}
                                <span>: 1</span>
                              </div>
                            </div>
                          </OptMBX>
                        )}
                      </div>
                      <div className="gwFormRMbox">
                        <div className="gwFormSTitle01">
                          <span>2</span>Set up your design{' '}
                        </div>
                        <div className="gwFormSbox01">
                          <div className="gwFormTitle01">Choose your PDO pad displaying</div>
                          <div className="gwFormSFormbox02">
                            <div className="md-radio md-radio-inline w50">
                              <input
                                type="radio"
                                id="r011"
                                checked={pdoDisplay === 'SINGLE'}
                                onClick={() => setPdoDisplay('SINGLE')}
                                name="stepina1"
                                value="option146"
                              />
                              <label htmlFor="r011">
                                Single display <ToolTip tip={'Coming Soon'} id={'m4'} />
                              </label>
                            </div>
                            <div className="md-radio md-radio-inline w50">
                              <input
                                type="radio"
                                id="r012"
                                checked={pdoDisplay === 'CROSS'}
                                onClick={() => setPdoDisplay('CROSS')}
                                name="stepina1"
                                value="option145"
                              />
                              <label htmlFor="r012">
                                Cross display <ToolTip tip={'Coming Soon'} id={'m4'} />
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* <div className="gwFormSbox01">
                                                <div className="gwFormTitle01" >Add your native token smart contract <ToolTip tip={"Coming Soon"} id={'m4'} /></div>
                                                <div className="gwFormSFormbox01">
                                                    <input type="text" /> <span>TOKEN</span>
                                                </div>
                                            </div> */}
                        <div className="gwFormSbox01">
                          <div className="gwFormTitle01">
                            Do you want to display PDOs with your native token pools on the top?
                            <ToolTip
                              tip={
                                'You can utilize this option only if you chosen blockchains and added your native tokens smart contract'
                              }
                              id={'m4'}
                            />{' '}
                          </div>
                          <div className="gwFormSFormbox02">
                            <div className="md-radio md-radio-inline w50">
                              <input
                                type="radio"
                                id="r015"
                                checked={isNativeOnTop}
                                onClick={() => setIsNativeOnTop(true)}
                                name="stepina3"
                                value="option146"
                              />
                              <label htmlFor="r015">Yes</label>
                            </div>
                            <div className="md-radio md-radio-inline w50">
                              <input
                                type="radio"
                                id="r016"
                                checked={!isNativeOnTop}
                                onClick={() => setIsNativeOnTop(false)}
                                name="stepina3"
                                value="option145"
                              />
                              <label htmlFor="r016">No</label>
                            </div>
                          </div>
                        </div>
                        {/* <div className="gwFormSbox01">
                                                <div className="colorPlateTitle01"><button>Add more option</button></div>
                                            </div> */}
                        <div className="gwFormSbox01 ">
                          <div className="gwFormTitle01">
                            Choose your subdomain name{' '}
                            <ToolTip
                              tip={
                                'After you choose your subdomain, you can create a redirect with a mask to it, from your own main domain. so your users will not even know about that subdomain and it will look and fell like it yours.'
                              }
                              id={'m4'}
                            />
                          </div>
                          <div className="gwFormSFormbox01 v2 smFixer03">
                            <input type="text" value={domainName} onChange={handleDomain} /> <span>.PDO.finance</span>
                          </div>
                        </div>

                        {/* <div className="gwFormSbox01 NpDisplyFix01 ">
                                                <div className="gwFormSubBox01 smFixer04">Add logo
                                                    <div className="SSMainInputBX ">
                                                        <input placeholder="Choose file" className="ssInputtype01" />
                                                        <label className="custom-file-input" >
                                                            <input type="file" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="gwFormSubBox01 smFixer04">Add a URL link behind your logo
                                                    <div className="SSMainInputBX">
                                                        <input placeholder="http:///www.yourwebsite.com" style={{ width: '100%', color: 'white' }} className="ssInputtype01" />
                                                    </div>
                                                </div>
                                            </div> */}

                        {/* ---------------------start-here----------------------------- */}
                        <div className="gwFormSbox01 NpDisplyFix01 ">
                          <div className="gwFormSubBox01 smFixer04">
                            Add logo
                            <div className="SSMainInputBX ">
                              <input placeholder="Choose file" className="ssInputtype01" />
                              <label className="custom-file-input">
                                <input
                                  accept="image/*"
                                  id="contained-button-file-logo"
                                  type="file"
                                  style={{ display: 'none' }}
                                  onChange={changeLogo}
                                />
                              </label>
                            </div>
                          </div>
                          <div className="gwFormSubBox01 smFixer04">
                            Add a URL link behind your logo
                            <div className="SSMainInputBX">
                              <input
                                placeholder="http:///www.yourwebsite.com"
                                style={{ width: '100%', color: 'white' }}
                                className="ssInputtype01"
                                type="text"
                                value={logoUrl}
                                onChange={changeurl}
                              />
                            </div>
                          </div>
                        </div>
                        {logoImage !== '' && (
                          <div className="gwFormSbox01 NpDisplyFix01 ">
                            <img src={logoImage} style={{ maxWidth: '100%' }}></img>
                          </div>
                        )}
                        <div className="gwFormSbox01 NpDisplyFix01 smFixer04">
                          <div className="gwFormSubBox01">
                            Add new background
                            <div className="SSMainInputBX">
                              <input placeholder="Choose file" className="ssInputtype01" />
                              <label className="custom-file-input">
                                <input
                                  accept="image/*"
                                  id="contained-button-file-background"
                                  type="file"
                                  style={{ display: 'none' }}
                                  onChange={changeBg}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        {bgimage !== '' && (
                          <div className="gwFormSbox01 NpDisplyFix01 ">
                            <img src={bgimage} style={{ maxWidth: '100%' }}></img>
                          </div>
                        )}

                        <div className="gwFormSbox01" ref={fontMenu}>
                          <div className="gwFormTitle01">Select font style</div>
                          <div className="LiproDropdown">
                            <button className="LiproDDbtn01" onClick={() => setFontCollpase(!fontCollpase)}>
                              <div className="ddIconBX">{fontStyle}</div>
                              <i className="fas fa-caret-down"></i>
                            </button>
                            <div className="ddContainer">
                              <Collapse isOpened={fontCollpase} className={'collapse-css-transition'}>
                                <button
                                  className="LiproDDbtn01"
                                  onClick={() => {
                                    setFontStyle('Default')
                                    setFontCollpase(false)
                                  }}
                                >
                                  <div className="ddIconBX">Default</div>
                                </button>
                                <button
                                  className="LiproDDbtn01"
                                  onClick={() => {
                                    setFontStyle('Montserrat')
                                    setFontCollpase(false)
                                  }}
                                >
                                  <div className="ddIconBX">Montserrat</div>
                                </button>
                                <button
                                  className="LiproDDbtn01"
                                  onClick={() => {
                                    setFontStyle('Nunito')
                                    setFontCollpase(false)
                                  }}
                                >
                                  <div className="ddIconBX">Nunito</div>
                                </button>
                                <button
                                  className="LiproDDbtn01"
                                  onClick={() => {
                                    setFontStyle('Poppins')
                                    setFontCollpase(false)
                                  }}
                                >
                                  <div className="ddIconBX">Poppins</div>
                                </button>
                              </Collapse>
                            </div>
                          </div>
                        </div>

                        <div className="colorPlateMBox npFixer05">
                          <div className="colorPlateTitle01">Text color</div>
                          <div className="colorPlateSBox01">
                            <div className="md-radio md-radio-inline ">
                              <input type="radio" id="s01" name="s11" value="s11" defaultChecked />
                              <label htmlFor="s01" onClick={() => setPrimaryColors('#fcf302')}></label>
                            </div>
                            <div className="colorPlater">
                              Current color <div className="ColorBX" style={{ backgroundColor: '#fcf302' }}></div>
                            </div>
                            <div className="chevrBox">
                              <i className="fas fa-chevron-right"></i>
                            </div>
                          </div>
                          <div className="colorPlateSBox02" ref={primaryColorRef}>
                            <div className="md-radio md-radio-inline ">
                              <input
                                type="radio"
                                id="s02"
                                name="s11"
                                value="s11"
                                defaultChecked={primaryColors !== '#fcf302'}
                              />
                              <label
                                htmlFor="s02"
                                onClick={e => {
                                  toggalColor(e, 's02')
                                  setShowPrimaryColor(true)
                                }}
                              ></label>
                            </div>
                            <div className="colorPlater">
                              New color
                              <span className="colorPlaterSbx" onClick={() => setShowPrimaryColor(!showPrimaryColor)}>
                                <div className="ColorBX" style={{ backgroundColor: primaryColors }}></div>
                                <button>
                                  {' '}
                                  <i className="fas fa-sort-down"></i>{' '}
                                </button>
                              </span>
                              {showPrimaryColor && (
                                <div className="color-picker">
                                  <ChromePicker
                                    color={primaryColors}
                                    onChangeComplete={color => {
                                      setPrimaryColors(color.hex)
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            {/* {showPrimaryColor && (
                                                        <>
                                                            <div
                                                                className="overPicker"
                                                                onClick={closeChromePickerPopUp}
                                                            ></div>

                                                        </>
                                                    )} */}
                          </div>
                        </div>

                        <div className="colorPlateMBox npFixer05">
                          <div className="colorPlateTitle01">Button Color</div>
                          <div className="colorPlateSBox01">
                            <div className="md-radio md-radio-inline ">
                              <input type="radio" id="s05" name="s13" value="s13" defaultChecked />
                              <label htmlFor="s05" onClick={() => setSeconderyColor('#14b745')}></label>
                            </div>
                            <div className="colorPlater">
                              Current color <div className="ColorBX" style={{ backgroundColor: '#14b745' }}></div>
                            </div>
                            <div className="chevrBox">
                              <i className="fas fa-chevron-right"></i>
                            </div>
                          </div>
                          <div className="colorPlateSBox02" ref={secondaryColorRef}>
                            <div className="md-radio md-radio-inline ">
                              <input
                                type="radio"
                                id="s06"
                                name="s13"
                                value="s13"
                                defaultChecked={seconderyColor !== '#14b745'}
                              />
                              <label
                                htmlFor="s06"
                                onClick={e => {
                                  toggalColor(e, 's06')
                                  setShowSecondaryColor(true)
                                }}
                              ></label>
                            </div>
                            <div className="colorPlater">
                              New color
                              <span
                                className="colorPlaterSbx"
                                onClick={() => setShowSecondaryColor(!showSecondaryColor)}
                              >
                                <div className="ColorBX" style={{ backgroundColor: seconderyColor }}></div>
                                <button>
                                  {' '}
                                  <i className="fas fa-sort-down"></i>{' '}
                                </button>
                              </span>
                              {showSecondaryColor && (
                                <div className="color-picker">
                                  <ChromePicker
                                    color={seconderyColor}
                                    onChangeComplete={color => {
                                      setSeconderyColor(color.hex)
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="colorPlateMBox">
                          <div className="colorPlateTitle01">
                            <button onClick={previewCalled}>Preview</button>
                          </div>
                        </div>
                      </div>
                      <TAC onClick={handleCheckBox}>
                        <i className={isChecked ? 'far fa-check-square' : 'far fa-square'}></i>I agree to the Term and
                        Conditions of licensing use
                      </TAC>
                    </div>
                    <div className="gwFormSFormbox03">
                      {!(account && ACTIVE_NETWORK.includes(chainId)) ? (
                        <button className="gwFormBTN01" onClick={toggleWalletModal}>
                          Connect Wallet
                        </button>
                      ) : (
                        <button
                          className={`gwFormBTN01  ${isChecked ? 'green' : 'disabled'}`}
                          onClick={checkAndValidateAndDeploy}
                        >
                          Create A License
                        </button>
                      )}
                    </div>
                    <div className="gwFormSFormbox04">
                      By creating a license, you are accepting all the{' '}
                      <span onClick={() => setTermsVisible(true)}>terms and conditions.</span>
                    </div>
                  </>
                )}

                {showLicenseDetails && (
                  <>
                    <hr style={{ margin: '60px 0px', borderColor: '#5a5e67' }} />

                    <BTitle>Below is Your License URL and Smart Contract</BTitle>
                    <OptMBX className="m-0">
                      <div className="colorPlateTitle01">Your licensee sub domain</div>
                      <GPLInputMBX>
                        {/* <CopyBTN><i className="fas fa-copy"></i></CopyBTN> */}
                        <input type="text" defaultValue={`https://${domainName}.pdo.finance`} readOnly={true} />
                      </GPLInputMBX>
                      <div className="gwFormSbox01">
                        <div className="gwFormTitle01">
                          Change your fees that you want to charge your users
                          <ToolTip tip={'Coming Soon'} id={'m2'} />
                        </div>
                        <RSOuter>
                          <div className="dragorInput v2">
                            <InputRange
                              maxValue={250}
                              minValue={0}
                              value={fee}
                              formatLabel={value => `${value / 100}%`}
                              onChange={value => () => {}}
                            />
                          </div>
                          {/* <Savebtn>UPDATE</Savebtn> */}
                        </RSOuter>
                      </div>
                      {isExtrafee && (
                        <>
                          <div className="colorPlateTitle01">Below is Extra Fee</div>
                          {amountUsd.map((row, i) => (
                            // eslint-disable-next-line react/jsx-key
                            <div className="colorPlateTitle01">
                              <div>
                                ${amountUsd[i]} <span style={{ color: '#00f02b' }}>{extrafee[i]} %</span>
                              </div>
                            </div>
                          ))}
                        </>
                      )}

                      {isReimburse && (
                        <>
                          <GPLInputMBX style={{ marginBottom: '10px' }}>
                            <CopyBTN>
                              <i className="fas fa-copy"></i>
                            </CopyBTN>
                            <input type="text" defaultValue={ZERO_ADDRESS} value={bscLicenseAddress} />
                          </GPLInputMBX>
                          <ColorFulText>
                            {/* Created: July 21, 2021 11:23:01 am UTC | <a href="#" className='green'>Deposit your reimbursement tokens to this smart contract</a> | Balance: 8569.563 [TOKEN] | <a href="#" className='red'>Withdraw your reimbursement tokens</a> */}
                          </ColorFulText>
                          <div className="gwFormSbox01">
                            <div className="gwFormTitle01">Your native token smart contract on BSC</div>
                            <div className="gwFormSFormbox01 smFixer03">
                              <input type="text" value={tokenAddress} readOnly={true} /> <span>{name}</span>
                            </div>
                          </div>
                          <div className="gwFormSbox01">
                            <div className="gwFormTitle01">Your router smart contract on BSC</div>
                            <div className="gwFormSFormbox01 smFixer03">
                              <input type="text" value={routerAddress} readOnly={true} /> <span>{name}</span>
                            </div>
                          </div>
                          <div className="gwFormSbox01">
                            <div className="gwFormTitle01">
                              how many days users need to stake to get 100% reimbursement?{' '}
                              <ToolTip tip={'Coming Soon'} id={'m4'} />
                            </div>
                            <div className="gwFormSFormbox01 smFixer03">
                              <input type="text" value={reDays} readOnly={true} /> <span>DAYS</span>
                            </div>
                          </div>
                          <div className="gwFormSbox01">
                            <div className="gwFormTitle01">
                              token ratio users will need to stake <ToolTip tip={'Coming Soon'} id={'m4'} />
                            </div>
                            <div className="gwFormSFormbox01 smFixer03">
                              <input type="text" value={reRatio} readOnly={true} /> <span>: 1</span>
                            </div>
                          </div>
                        </>
                      )}

                      {/* 

                                        <div className="gwFormSbox01 ">
                                            <div className="gwFormTitle01">Choose the DEX that has TOKEN&lt;&gt;BSC pool <ToolTip tip={"Coming Soon"} id={'m4'} /></div>
                                            <div className="gwFormSFormbox01 smFixer03">
                                                <input type="text" /> <span>TOKEN</span>
                                            </div>
                                        </div>

                                        <div className="gwFormSbox01">
                                            <div className="gwFormTitle01">Change how many days users need to stake to get 100% reimbursement? <ToolTip tip={"Coming Soon"} id={'m4'} /></div>
                                            <div className="gwFormSFormbox01 smFixer03">
                                                <input type="text" /> <span>DAYS</span>
                                            </div>
                                        </div>

                                        <div className="gwFormSbox01">
                                            <div className="gwFormTitle01">Change the token ratio users will need to stake <ToolTip tip={"Coming Soon"} id={'m4'} /></div>
                                            <div className="gwFormSFormbox01 smFixer03">
                                                <input type="text" /> <span>: 1</span>
                                            </div>
                                        </div>

                                        <button className="gwFormBTN01 green-text" style={{ fontSize: "24px" }}><i className="fa fa-check" aria-hidden="true"></i>&nbsp; BSC LICENSE BEEN CREATED SUCCESSFULLY</button> */}
                    </OptMBX>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <ClosBTN onClick={() => setRedirect(true)} />
      </MainPOPBX>
    </>
  )
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`
const ClosBTN = styled.button`
  display: block;
  width: 29px;
  height: 29px;
  background: url(${CloseIMG}) 50% 50% no-repeat;
  position: absolute;
  right: 20px;
  top: 20px;
  border: none;
  outline: none;
  transition: 400ms ease-in-out;
  :hover {
    transform: rotate(180deg);
  }
`
const BTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin: 0px 0px 60px;
  text-align: center;
`

const CopyBTN = styled.button`
  font-size: 19px;
  border: none;
  outline: none;
  position: absolute;
  top: 12px;
  right: 9px;
  color: white;
  background-color: transparent;
  :hover {
    opacity: 0.7;
  }
  &.v2 {
    position: initial;
  }
`

const RSOuter = styled(FlexDiv)`
  align-items: flex-end;
  .dragorInput {
    width: calc(100% - 199px);
  }
`

const Savebtn = styled.button`
  width: 165px;
  height: 50px;
  margin-left: 34px;
  background-color: #00f02b;
  font-size: 14px;
  font-weight: bold;
  color: #5d6168;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ColorFulText = styled.div`
  font-size: 11px;
  font-weight: normal;
  color: #8e9195;
  margin-bottom: 40px;
  a {
    &.green {
      color: #00f02b;
    }
    &.red {
      color: #f43436;
    }
  }
`

const MainPOPBX = styled(FlexDiv)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(11, 12, 15, 0.95);
  z-index: 10;
  min-height: 100vh;
  overflow-y: auto;

  .swap-Block {
    margin-top: 60px;
    .smeTitle01-v2 {
      color: #fff;
      margin-bottom: 45px;
    }
  }
  .smeText01 {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    line-height: 30px;
    width: 100%;
    max-width: 1360px;
    margin: 0 auto;
    a {
      color: #00f02b;
      text-decoration: underline;
      :hover {
        color: #fff;
      }
    }
    p {
      margin: 0px;
    }
  }
  .smeMBX {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    &.brFix01 {
      padding-bottom: 50px;
      border-bottom: 1px solid #5a5e67;
      margin-bottom: 50px;
    }
  }
  .swap-textBox {
    padding-top: 80px;
    .gwFormSbox01 {
      .dragorInput.v2 .input-range__slider {
        width: 63px;
        height: 45px;
        border-radius: 10px;
        border: none;
      }
      .dragorInput.v2 .input-range__label--value {
        left: calc(50% - 27px);
        top: -29px;
        width: 33px;
        text-align: center;
      }
      .dragorInput.v2 .input-range__label--min {
        top: 15px;
      }
      .dragorInput.v2 .input-range__label--max {
        top: 15px;
      }
      .dragorInput.v2 .input-range__label-container {
        color: #fff;
        font-size: 14px;
        font-weight: bold;
      }

      .dragorInput .input-range__slider {
        width: 45px;
        height: 45px;
        border: none;
      }
      .dragorInput .input-range__label--value {
        left: calc(50% - 37px);
        top: -29px;
        width: 33px;
        text-align: center;
      }
      .dragorInput .input-range__label--min {
        top: 15px;
      }
      .dragorInput .input-range__label--max {
        top: 15px;
      }
      .dragorInput .input-range__label-container {
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        left: 0px;
      }
    }
    .gwFormSFormbox03 {
      margin-top: 15px;
      .gwFormBTN01 {
        max-width: 662px;
        margin: 0 auto;
        padding: 21px 15px;
      }
      .disabled {
        background: #5d6168;
        color: #fff;
      }
    }
    .gwFormSFormbox04 {
      max-width: 662px;
      margin: 0 auto;
      padding: 21px 15px;
      color: #808080;
    }
    .gwFormSFormbox04 span {
      color: #fff;
    }
    .gwFormSFormbox04 span:hover {
      color: #00f02b;
      cursor: pointer;
    }
  }
  .smeSBX01 {
    width: 50%;
    min-height: 100%;
  }
  .smeAlertTxt {
    font-size: 12px;
    font-weight: 300;
    color: #00f02b;
    margin: 8px 0 0 31px;
  }
  .gwFormSFormbox02 .md-radio.w50 {
    width: 50%;
  }
  .gwFormSFormbox02 .md-radio label:before {
    width: 18px;
    height: 18px;
    top: 3px;
    left: 3px;
  }
  .gwFormSFormbox02 .md-radio.withInput label:before {
    top: 20px;
  }
  .gwFormSFormbox02 .md-radio.withInput label:after {
    top: 24px;
  }

  .gwFormSFormbox02 .md-checkbox.w33 {
    width: 33.33%;
  }

  .small-btn {
    font-size: 15px;
  }
  .green-text {
    color: #00f02b;
  }

  .colorPlateTitle01 button {
    padding: 0px;
  }
  .ssInputtype01::placeholder {
    color: #fff;
  }

  ${Media.lg} {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${Media.sm} {
    .mainBlock {
      width: 100%;
    }
  }

  .overPicker {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
  .color-picker {
    position: absolute;
    top: 61px;
    z-index: 2;
    right: 2px;
  }
`
const GWMTitle01 = styled(FlexDiv)`
  color: #00f02b;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 30px;
`

const OptMBX = styled(FlexDiv)`
  width: 100%;
  justify-content: flex-start;
  padding: 25px;
  border: 2px solid #5a5e67;
  margin: 20px 0 70px 0;
  border-radius: 10px;
  position: relative;
  .colorPlateTitle01 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    i.fa-caret-down {
      color: #00f02b;
      transition: 0.5s ease all;
      &.active {
        color: #fff;
        transform: rotate(180deg);
      }
    }
  }
  &.m-0 {
    margin: 0px;
  }
  &.bb-radius {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 0px;
  }
  &.bt-radius {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .closeBtn {
    background: transparent;
    font-size: 18 px;
    color: white;
    position: absolute;
    right: 15px;
    top: 15px;
    border: none;
    outline: none;
  }
`
const GPLInputMBX = styled(FlexDiv)`
  width: 100%;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 36px;

  input {
    width: 100%;
    height: 52px;
    border: 2px solid #5a5e67;
    border-radius: 10px;
    padding: 4px 32px 4px 8px;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
  }
`

const TAC = styled(FlexDiv)`
  font: 700 20px/12px 'Kanit', arial;
  text-align: center;
  color: #00f02b;
  display: flex;
  flex-direction: row;
  width: fit-content;
  line-height: 18px;
  .far {
    margin-right: 8px;
  }
  margin: 50px 0 0 0;
  cursor: pointer;
`
