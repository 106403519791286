import React from 'react'
import styled from 'styled-components'
// import { CardProps, Text } from 'rebass'
// import { Box } from 'rebass/styled-components'
// import {Collapse} from 'react-collapse'
import { escapeRegExp } from '../../utils'

const InputContainer = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  margin-left: auto;
`

const Input = styled.input`
  border: 2px solid ${({ theme }) => theme.border02};
  color: ${({ theme }) => theme.ifoText1};
  width: 100%;
  background-color: transparent;
  /* border: 2px solid #656565; */
  padding: 16px 16px;
  /* color: #fff; */
  font-weight: 700;
  font-size: 14px;
  border-radius: 10px;
`

interface TextBoxProps {
  value: string | number
  onUserInput: (string) => void
  placeholder: string
  readOnly?: boolean
}

const inputRegex = RegExp(/^\d*\.?\d*$/) // match escaped "." characters via in a non-capturing group

export default function TextBox({ value, onUserInput, placeholder, readOnly }: TextBoxProps) {
  // const changeInput = (nextUserInput: string) => {
  //     onUserInput(nextUserInput)
  // }

  const enforcer = (nextUserInput: string) => {
    console.log('nextUserInput', nextUserInput, inputRegex.test(nextUserInput))
    if (inputRegex.test(nextUserInput)) {
      onUserInput(nextUserInput)
    }
  }
  return (
    <InputContainer>
      <Input
        type="text"
        value={value}
        onChange={event => {
          enforcer(event.target.value)
        }}
        inputMode="decimal"
        autoComplete="off"
        autoCorrect="off"
        minLength={1}
        maxLength={79}
        spellCheck="false"
        placeholder={placeholder}
        readOnly={readOnly}
      ></Input>
    </InputContainer>
  )
}
