import { InjectedConnector } from '@web3-react/injected-connector'
// import { BinanceConnector } from '@bscswap/binance-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { NetworkConnector } from './NetworkConnector'


export const SUPPORTED_NETWORK = [
  { name: 'BSC', chainId: 56, symbol: 'BNB', color: 'green', icon: 'bsc.png', rpc: 'https://rpc.ankr.com/bsc' },
  { name: 'Polygon', chainId: 137, symbol: 'MATIC', color: '#8247e5', icon: 'matic.png', rpc: 'https://rpc.ankr.com/polygon' },
  { name: 'Arbitrum One', chainId: 42161, symbol: 'ETH', color: '#28a0f0', icon: 'arbitrum.png', rpc: 'https://rpc.ankr.com/arbitrum' }
]

export const ACTIVE_NETWORK:number[] = SUPPORTED_NETWORK.map(items => items.chainId);
export const NETWORK_URL:string[] = SUPPORTED_NETWORK.map(items => items.rpc);
export const CHAIN_ID_WISE_URL:any =  {}
export const CHAIN_ID_WISE_NAME:any =  {}
export const CHAIN_ID_WISE_SYMBOL:any =  {}
export const CHAIN_ID_WISE_LOGO:any =  {56:'bsc.svg',137:'matic.svg'}

SUPPORTED_NETWORK.map(items => {
  CHAIN_ID_WISE_URL[items.chainId] = items.rpc;
  CHAIN_ID_WISE_NAME[items.chainId] = items.name;
  CHAIN_ID_WISE_SYMBOL[items.chainId] = items.symbol;
});



export const network = new NetworkConnector({
  urls: CHAIN_ID_WISE_URL,
  defaultChainId:ACTIVE_NETWORK[0]
})

export const injected = new InjectedConnector({
  supportedChainIds: ACTIVE_NETWORK
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  supportedChainIds: ACTIVE_NETWORK,
  rpc: CHAIN_ID_WISE_URL
})


