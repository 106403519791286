import React from 'react'
import { Redirect, RouteComponentProps ,useLocation} from 'react-router-dom'
import { useQuery } from '../../hooks/useQuery';



// Redirects to swap but only replace the pathname
export function RedirectPathToListOnly({ location }: RouteComponentProps) {
  const ref = localStorage.getItem('ref');
  if(ref !== '')
    return <Redirect to={{pathname: `/list/?ref=${ref}` }} />
  else
    return <Redirect to={{ ...location, pathname: '/list' }} />
}

// Redirects from the /swap/:outputCurrency path to the /swap?outputCurrency=:outputCurrency format
export function RedirectToHome(props: RouteComponentProps<{ outputCurrency: string }>) {
  const {
    location: { search },
    match: {
      params: { outputCurrency }
    }
  } = props

  return (
    <Redirect
      to={{
        ...props.location,
        pathname: '/home',
        search:
          search && search.length > 1
            ? `${search}&outputCurrency=${outputCurrency}`
            : `?outputCurrency=${outputCurrency}`
      }}
    />
  )
}
