import { Interface } from '@ethersproject/abi'
import { ChainId } from '@bscswap/sdk'
import FACTORY_PDO_ABI from './factory.json'
import PDO_MAIN_ABI from './pdoMain.json'
import ORDER_BOOK_ABI from './orderAbi.json'
import PDO_LICENSES_ABI from './licenseAbi.json'
import DUMPER_SHEILD_ABI from './dumperSheild.json'

const FACTORY_PDO_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95',
  [ChainId.ROPSTEN]: '0x9c83dCE8CA20E9aAF9D3efc003b2ea62aBC08351',
  [ChainId.RINKEBY]: '0xf5D915570BC477f9B8D6C0E980aA81757A3AaC36',
  [ChainId.GÖRLI]: '0x6Ce570d02D73d4c384b46135E87f8C592A8c86dA',
  [ChainId.KOVAN]: '0xD3E51Ef092B2845f10401a0159B2B96e8B6c3D30',
  [ChainId.BSC_MAINNET]: '0x20f44334031090cB08eFaD60722c2E55104D7cAb',
  [ChainId.BSC_TESTNET]: '0x453Ce7098D3D1135e01F58a433B781B10ED2efc7',
  [ChainId.POLYGON_MAINNET]: '0x6C270EDf236D0076bb5E4aB3BB8E515c5ECaC2e1',
  [ChainId.POLYGON_TESTNET]: '0x75CeA8439e23269f79DB36caB245F0d6429bCe36',
  [ChainId.ARBITRUM_ONE]:'0x07337a2370b73D4beE372BF67E6D3B2D6e0D3e84'
}

const ORDER_BOOK_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95',
  [ChainId.ROPSTEN]: '0x9c83dCE8CA20E9aAF9D3efc003b2ea62aBC08351',
  [ChainId.RINKEBY]: '0xf5D915570BC477f9B8D6C0E980aA81757A3AaC36',
  [ChainId.GÖRLI]: '0x6Ce570d02D73d4c384b46135E87f8C592A8c86dA',
  [ChainId.KOVAN]: '0xD3E51Ef092B2845f10401a0159B2B96e8B6c3D30',
  [ChainId.BSC_MAINNET]: '0xf6aB8741DE05209cBCD8822Cb99982bf0B22FF60',
  [ChainId.BSC_TESTNET]: '0x1f30FA8a35109502145DD662fBa98384c9feF904',
  [ChainId.POLYGON_MAINNET]: '0x03183E867B15F25d6838A27c3743fC0D36B2D40f',
  [ChainId.POLYGON_TESTNET]: '0x75CeA8439e23269f79DB36caB245F0d6429bCe36',
  [ChainId.ARBITRUM_ONE]:''
}

const DUMPER_SHEILD_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95',
  [ChainId.ROPSTEN]: '0x9c83dCE8CA20E9aAF9D3efc003b2ea62aBC08351',
  [ChainId.RINKEBY]: '0xf5D915570BC477f9B8D6C0E980aA81757A3AaC36',
  [ChainId.GÖRLI]: '0x6Ce570d02D73d4c384b46135E87f8C592A8c86dA',
  [ChainId.KOVAN]: '0xD3E51Ef092B2845f10401a0159B2B96e8B6c3D30',
  [ChainId.BSC_MAINNET]: '0x2aC982F7E5BCE4Fd28D80E6633D45dA1c9398e72',
  [ChainId.BSC_TESTNET]: '0xe36234dbc2380c96b82D76c80D55309F40e48CbF',
  [ChainId.POLYGON_MAINNET]: '0xd845955bd6f9770abe5c9d0bc8812a156f2080b0',
  [ChainId.POLYGON_TESTNET]: '0x75CeA8439e23269f79DB36caB245F0d6429bCe36',
  [ChainId.ARBITRUM_ONE]:'0xF1eBc61e23137fb19c8B67Ad72769F6fB470Ff75'
}

const PDO_LICENSES_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95',
  [ChainId.ROPSTEN]: '0x9c83dCE8CA20E9aAF9D3efc003b2ea62aBC08351',
  [ChainId.RINKEBY]: '0xf5D915570BC477f9B8D6C0E980aA81757A3AaC36',
  [ChainId.GÖRLI]: '0x6Ce570d02D73d4c384b46135E87f8C592A8c86dA',
  [ChainId.KOVAN]: '0xD3E51Ef092B2845f10401a0159B2B96e8B6c3D30',
  [ChainId.BSC_MAINNET]: '0xB1eC44Ea2CEEEb1FD3Df3E8A83C2B1D14951EA9B',
  [ChainId.BSC_TESTNET]: '0x41D6e2e797D0961c0966FE210648e1C5D0b0B8Db',
  [ChainId.POLYGON_MAINNET]: '0xa2D1D590f3CaA24F4f892c57F55C201190917090',
  [ChainId.POLYGON_TESTNET]: '0x75CeA8439e23269f79DB36caB245F0d6429bCe36',
  [ChainId.ARBITRUM_ONE]:'0x00590D9ed08B58A72A22D07E7c8F065d7629BbEf'
}

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
//
// 0xc3c06Ce3d945ADb6b6EaacfF1857219B36490C6e
// 0x99368A3250169e78192bd8C9595A96CBd06F78a3 local
// 0x7Aaf647B8c2a088426F6F9E794D2bB9273F91d11

//0x20128d8c232F9856A6BE4b844972C726923F0331
const ORDER_BOOK_INTERFACE = new Interface(ORDER_BOOK_ABI)
// const IFO_INTERFACE = new Interface(IFO_ABI)

const PDO_MAIN_INTERFACE = new Interface(PDO_MAIN_ABI)
export {
  FACTORY_PDO_ABI,
  FACTORY_PDO_ADDRESSES,
  ZERO_ADDRESS,
  PDO_MAIN_ABI,
  PDO_MAIN_INTERFACE,
  ORDER_BOOK_ADDRESSES,
  ORDER_BOOK_ABI,
  ORDER_BOOK_INTERFACE,
  DUMPER_SHEILD_ADDRESSES,
  DUMPER_SHEILD_ABI,
  PDO_LICENSES_ABI,
  PDO_LICENSES_ADDRESSES
}
