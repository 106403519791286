import { Interface } from '@ethersproject/abi'
import { ChainId } from '@bscswap/sdk'
import FACTORY_IFO_ABI from './factory_ifo.json'
import IFO_ABI from './ifo.json'

const FACTORY_IFO_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95',
  [ChainId.ROPSTEN]: '0x9c83dCE8CA20E9aAF9D3efc003b2ea62aBC08351',
  [ChainId.RINKEBY]: '0xf5D915570BC477f9B8D6C0E980aA81757A3AaC36',
  [ChainId.GÖRLI]: '0x6Ce570d02D73d4c384b46135E87f8C592A8c86dA',
  [ChainId.KOVAN]: '0xD3E51Ef092B2845f10401a0159B2B96e8B6c3D30',
  [ChainId.BSC_MAINNET]: '0x704639248B352423697D82820d407232c932C969',
  [ChainId.BSC_TESTNET]: '0x7240dbc7899F9bE4184BF136C23384e870C116c6',
  [ChainId.POLYGON_MAINNET]: '0x03183E867B15F25d6838A27c3743fC0D36B2D40f',
  [ChainId.POLYGON_TESTNET]: '0x75CeA8439e23269f79DB36caB245F0d6429bCe36',
  [ChainId.ARBITRUM_ONE]:''
}

//
// 0xc3c06Ce3d945ADb6b6EaacfF1857219B36490C6e
// 0x99368A3250169e78192bd8C9595A96CBd06F78a3 local
// 0x7Aaf647B8c2a088426F6F9E794D2bB9273F91d11

//0x20128d8c232F9856A6BE4b844972C726923F0331
const FACTORY_IFO_INTERFACE = new Interface(FACTORY_IFO_ABI)
const IFO_INTERFACE = new Interface(IFO_ABI)

export { FACTORY_IFO_ADDRESSES, FACTORY_IFO_INTERFACE, FACTORY_IFO_ABI, IFO_INTERFACE, IFO_ABI }
