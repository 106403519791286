// import { BigNumber } from '@ethersproject/bignumber'
// import { Token/*, TokenAmount*/ } from '@bscswap/sdk'
import { useTokenContract } from '../hooks/useContract'
import { useSingleCallResult } from '../state/multicall/hooks'

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useTokenName(token?: string): string | undefined {
  const contract = useTokenContract(token, false)

  const name = useSingleCallResult(contract, 'name')?.result?.[0]

  return token && name ? name : undefined
}
