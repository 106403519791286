import React, { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding-right: 10px;
  position: relative;
`

const Input = styled.input`
  background: none !important;
  border: none !important;
  appearance: none;
  border-radius: 4px;
  display: none;
`
const Label = styled.label`
  color: ${({ theme }) => theme.ifoBg2};
  font-size: 14px !important;
  font-weight: 700;
  margin-left: 0 !important;
  margin-right: 30px;

  &:before {
    width: 16px !important;
    height: 16px !important;
    border: 2px solid #393d46 !important;
    border-radius: 4px !important;
    background: transparent !important;
    left: auto !important;
    right: 0;

    /* border: 2px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.05);
    left: auto;
    right: 0; */
  }
  &:after {
    transform: rotate(-45deg);
    top: -1px !important;
    left: auto !important;
    width: 15px;
    height: 9px;
    border: 3px solid #00f02b;
    border-top-style: none;
    border-right-style: none;
    right: -2px;
    /* left: 0;
        top: 0; */
  }
`
interface CheckBoxProps {
  id: string
  name: string
  title: string
  checked: boolean
}
export default function CheckBox({ id, name, title, checked }: CheckBoxProps) {
  const [check, setCheck] = useState(checked)
  return (
    <Container>
      <div className="md-checkbox" style={{ paddingRight: '10', marginBottom: '23px' }}>
        <Input
          type="checkbox"
          id={id}
          name={name}
          checked={check}
          onChange={event => {
            setCheck(event.target.checked)
            console.log(check)
          }}
        ></Input>
        <Label htmlFor={id}>{title}</Label>
      </div>
    </Container>
  )
}
