import { utils } from "ethers"

export default async function changeNetwork(chainName: string, chainId: number, rpcUrl: string, chainSymbol: string) {
  const hex_chainId = utils.hexValue(chainId)
  const { ethereum } = window;
  
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: hex_chainId }]
    })
    // handleClose()
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: hex_chainId,
              chainName: chainName,
              nativeCurrency: {
                name: chainName,
                symbol: chainSymbol,
                decimals: 18
              },
              rpcUrls: [rpcUrl]
            }
          ]
        })
        // handleClose()
      } catch (addError) {
        // handle "add" error
      }
    }
    // handle other "switch" errors
  }
}
