import React from 'react'
import styled, { css } from 'styled-components'

import { escapeRegExp } from '../../utils'

interface TextBoxNumaricProps {
  value: string | number
  onUserInput: (string) => void
  placeholder: string
  viewType?: number
}

// background-color: transparent;
// color: #fff;
// width: calc(100% - 108px);
// border: 0px;
// padding: 9px 8px;
// font-size: 12px;
// font-weight: 700;
const Input = styled.input<{ viewType?: number }>`
    background-color: transparent;
    color: ${({ theme }) => theme.ifoText1};
    border: 0px;
    padding: ${({ viewType }) => (viewType === 1 ? '16px 8px' : '9px 8px')};
    font-size: ${({ viewType }) => (viewType === 1 ? '18px' : '12px')};
    font-weight: 700;
 
    ${({ viewType }) =>
      viewType === 1 &&
      css`
        width: calc(100% - 180px);
      `}
}
`
const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

export default function TextBox({ value, onUserInput, placeholder, viewType }: TextBoxNumaricProps) {
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput)
    }
  }

  return (
    <Input
      viewType={viewType}
      type="text"
      value={value}
      onChange={event => {
        enforcer(event.target.value.replace(/,/g, '.'))
      }}
      inputMode="decimal"
      autoComplete="off"
      autoCorrect="off"
      pattern="^[0-9]*[.,]?[0-9]*$"
      minLength={1}
      maxLength={79}
      spellCheck="false"
      placeholder={placeholder}
    ></Input>
  )
}
