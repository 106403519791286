import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'

import application from './application/reducer'
import user from './user/reducer'
import transactions from './transactions/reducer'
// import swap from './swap/reducer'
// import mint from './mint/reducer'
import lists from './lists/reducer'
// import burn from './burn/reducer'
import multicall from './multicall/reducer'

import { updateVersion } from './user/actions'
import { pancakeswapApi } from './getPancakeswap'
import { uniswapApi } from './getUniswap'
import { sushiswapApi } from './getSushiswap'
import { tokensApi } from './getTokens'
import { bitqueryApi } from './bitquery'

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists']

const store = configureStore({
  reducer: {
    application,
    user,
    multicall,
    transactions,
    lists,
    [pancakeswapApi.reducerPath]: pancakeswapApi.reducer,
    [uniswapApi.reducerPath]: uniswapApi.reducer,
    [sushiswapApi.reducerPath]: sushiswapApi.reducer,
    [tokensApi.reducerPath]: tokensApi.reducer,
    [bitqueryApi.reducerPath]: bitqueryApi.reducer
  },
  middleware: [
    ...getDefaultMiddleware(),
    save({ states: PERSISTED_KEYS }),
    pancakeswapApi.middleware,
    uniswapApi.middleware,
    sushiswapApi.middleware,
    tokensApi.middleware,
    bitqueryApi.middleware
  ],
  preloadedState: load({ states: PERSISTED_KEYS })
})

store.dispatch(updateVersion())

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
