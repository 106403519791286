import React, { useState, useEffect } from 'react'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import styled from 'styled-components'
import { ACTIVE_NETWORK, NETWORK_URL, SUPPORTED_NETWORK } from '../../connectors'
import Modal from '../Modal'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { utils } from 'ethers'
import Option from './Option'
import changeNetwork from './NetworkChangerHook'

export default function NetworkSelection() {
  const { chainId } = useWeb3ReactCore()

  
  
  const filteredArray = SUPPORTED_NETWORK.filter(items => chainId !== items.chainId)

  return (
    <>
      {filteredArray.map(items => {
        return (
          <Option
            onClick={() => {
              changeNetwork(items.name, items.chainId, items.symbol,items.rpc)
            }}
            id={`connect-wallet-${items}`}
            key={''}
            active={chainId === items.chainId}
            color={''}
            link={''}
            header={items.name}
            subheader={null}
            size={40}
            icon={items.icon ? require(`../../assets/images/network/${items.icon}`) : ''}
          />
        )
      })}
    </>
  )
}
