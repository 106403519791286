import { Contract } from '@ethersproject/contracts'
import { ChainId /*, WETH*/ } from '@bscswap/sdk'
// import { abi as IBSCswapPairABI } from '@bscswap/contracts/build/IBSCswapPair.json'
import { useMemo } from 'react'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import UNISOCKS_ABI from '../constants/abis/unisocks.json'
import ERC20_ABI from '../constants/abis/erc20.json'

import ROUTER_ABI from '../constants/amm/router.json'
import FACTORY_ABI from '../constants/amm/factory.json'

// import WETH_ABI from '../constants/abis/weth.json'
// import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../constants/abis/migrator'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { /*V1_EXCHANGE_ABI,*/ V1_FACTORY_ABI, V1_FACTORY_ADDRESSES } from '../constants/v1'
import { FACTORY_IFO_ADDRESSES, /* FACTORY_IFO_INTERFACE,*/ FACTORY_IFO_ABI, IFO_ABI } from '../constants/ifo'

import {
  FACTORY_PDO_ADDRESSES,
  FACTORY_PDO_ABI,
  PDO_MAIN_ABI,
  ORDER_BOOK_ADDRESSES,
  ORDER_BOOK_ABI,
  PDO_LICENSES_ABI,
  PDO_LICENSES_ADDRESSES,
  DUMPER_SHEILD_ADDRESSES,
  DUMPER_SHEILD_ABI
} from '../constants/pdo'

import { getContract } from '../utils'
import { useActiveWeb3React } from './index'

// returns null on errors
function useContract(address?: string, ABI?: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useV1FactoryContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && V1_FACTORY_ADDRESSES[chainId], V1_FACTORY_ABI, false)
}

// export function useV2MigratorContract(): Contract | null {
//   return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI, true)
// }

// export function useV1ExchangeContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
//   return useContract(address, V1_EXCHANGE_ABI, withSignerIfPossible)
// }

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useRouterContract(routerAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(routerAddress, ROUTER_ABI, withSignerIfPossible)
}

export function useFactoryContract(factoryAddress?: string): Contract | null {
  return useContract(factoryAddress, FACTORY_ABI, false)
}

// export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
//   const { chainId } = useActiveWeb3React()
//   return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
// }

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

// export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
//   return useContract(pairAddress, IBSCswapPairABI, withSignerIfPossible)
// }

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}

export function useFactoryIFOContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && FACTORY_IFO_ADDRESSES[chainId], FACTORY_IFO_ABI, true)
}

export function useFactoryPDOContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && FACTORY_PDO_ADDRESSES[chainId], FACTORY_PDO_ABI, true)
}

export function useIFOContract(address: string): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, IFO_ABI, true)
}

export function usePDOContract(address: string): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, PDO_MAIN_ABI, true)
}

export function usePDOLicensesContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && PDO_LICENSES_ADDRESSES[chainId], PDO_LICENSES_ABI, true)
}

export function useDumperSheildContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && DUMPER_SHEILD_ADDRESSES[chainId], DUMPER_SHEILD_ABI, true)
}

export function useOrderContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && ORDER_BOOK_ADDRESSES[chainId], ORDER_BOOK_ABI, true)
}

export function useSocksController(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? '0x65770b5283117639760beA3F867b69b3697a91dd' : undefined,
    UNISOCKS_ABI,
    false
  )
}
