import React from 'react'
import styled from 'styled-components'
// import { CardProps, Text } from 'rebass'
// import { Box } from 'rebass/styled-components'
// import {Collapse} from 'react-collapse'

const InputContainer = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  margin-left: auto;
`

const Input = styled.input`
  border: 2px solid ${({ theme }) => theme.border02};
  color: ${({ theme }) => theme.ifoText1};
  width: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  /* border: 2px solid #656565; */
  padding: 16px 42px 16px 16px;
  /* color: #fff; */
  font-weight: 700;
  font-size: 16px;
  border-radius: 10px;
`

interface TextBoxProps {
  value: string | number
  onUserInput: (string) => void
  placeholder: string
}

export default function AddressTextBox({ value, onUserInput, placeholder }: TextBoxProps) {
  const changeInput = (nextUserInput: string) => {
    onUserInput(nextUserInput)
  }

  return (
    <InputContainer>
      <Input
        type="text"
        value={value}
        onChange={event => {
          changeInput(event.target.value)
        }}
        autoComplete="off"
        autoCorrect="off"
        minLength={1}
        maxLength={79}
        spellCheck="false"
        placeholder={placeholder}
      ></Input>
    </InputContainer>
  )
}
