// import { ChainId } from '@bscswap/sdk'
import React from 'react'
import { useActiveWeb3React } from '../../hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import Web3Status from '../Web3Status'
import Logo from '../../assets/images/pdo-logo.png'
import DarkLogo from '../../assets/images/pdo-logo.png'
import BSCIcon from '../../assets/images/bsc-icon.png'
import styled from 'styled-components'
import Settings from '../Settings'
import { useDarkModeManager } from '../../state/user/hooks'

import dexIcon01 from '../../assets/images/dexIcon-01.png'
import dexIcon02 from '../../assets/images/dexIcon-02.png'
import dexIcon03 from '../../assets/images/dexIcon-03.png'
import dexIcon04 from '../../assets/images/dexIcon-04.png'
import dexIcon05 from '../../assets/images/dexIcon-05.png'
import dexIcon06 from '../../assets/images/dexIcon-06.png'
import dexIcon07 from '../../assets/images/dexIcon-07.png'
import dexIcon08 from '../../assets/images/dexIcon-08.png'
import dexIcon09 from '../../assets/images/dexIcon-09.png'
import dexIcon010 from '../../assets/images/dexIcon-010.png'
import dexIcon011 from '../../assets/images/dexIcon-011.png'
import dexIcon012 from '../../assets/images/dexIcon-012.png'
import dexIcon013 from '../../assets/images/dexIcon-013.png'
import dexIcon014 from '../../assets/images/dexIcon-014.png'
import dexIcon015 from '../../assets/images/dexIcon-015.png'
import dexIcon016 from '../../assets/images/dexIcon-016.png'
import dexIcon017 from '../../assets/images/dexIcon-017.png'
import dexIcon018 from '../../assets/images/dexIcon-018.png'
import dexIcon019 from '../../assets/images/dexIcon-019.png'

import dexIcon020 from '../../assets/images/dexIcon-020.png' // ether
import dexIcon021 from '../../assets/images/dexIcon-021.png' // bsc
import dexIcon022 from '../../assets/images/dexIcon-022.png' //
import dexIcon023 from '../../assets/images/dexIcon-023.png'
import dexIcon024 from '../../assets/images/dexIcon-024.png'
import dexIcon025 from '../../assets/images/dexIcon-025.png' // matic
import dexIcon026 from '../../assets/images/dexIcon-026.png'
import dexIcon027 from '../../assets/images/dexIcon-027.png'
import dexIcon028 from '../../assets/images/dexIcon-028.png'
import dexIcon029 from '../../assets/images/dexIcon-029.png'
import dexIcon030 from '../../assets/images/dexIcon-030.png'
import dexIcon031 from '../../assets/images/dexIcon-031.png'
import dexIcon032 from '../../assets/images/dexIcon-032.png'
import dexIcon033 from '../../assets/images/dexIcon-033.png'
import dexIcon034 from '../../assets/images/dexIcon-034.png'
import dexIcon035 from '../../assets/images/dexIcon-035.png'
import dexIcon036 from '../../assets/images/dexIcon-036.png'
import dexIcon037 from '../../assets/images/dexIcon-037.png'
import dexIcon038 from '../../assets/images/dexIcon-038.png'
import dexIcon039 from '../../assets/images/dexIcon-039.png'
import dexIcon040 from '../../assets/images/dexIcon-040.png'
import dexIcon041 from '../../assets/images/dexIcon-041.png'

import Media from '../../theme/media-breackpoint'

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 20px 0 20px;
  margin-top: 20px;
`
const HeadCenterbox = styled.div`
  width: 100%;
  padding: 5px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  span {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    color: #5a5e67;
    margin-right: 20px;
  }
  a {
    display: flex;
    width: 34px;
    height: 34px;
    background-color: #393d46;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin: 5px 8px;
    :hover {
      img {
        filter: grayscale(1) opacity(0.4);
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }

    &.disable {
      filter: grayscale(1);
      cursor: normal;
      pointer-events: none;
    }

    ${Media.lg} {
      &:nth-last-child(01),
      &:nth-last-child(02),
      &:nth-last-child(03),
      &:nth-last-child(04) {
        display: none;
      }
    }
  }

  ${Media.xl} {
    max-width: 960px;
  }

  ${Media.lg} {
    max-width: 700px;
  }
  ${Media.md} {
    display: none;
  }
`

export default function Header() {
  return (
    <>
      <HeaderContainer>
        <HeadCenterbox>
          <span>Supporting DEXs:</span>
          <a href="">
            <img src={dexIcon01} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon02} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon03} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon04} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon05} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon06} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon07} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon08} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon09} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon010} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon011} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon012} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon013} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon014} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon015} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon016} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon017} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon018} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon019} alt="" />{' '}
          </a>
        </HeadCenterbox>

        <HeadCenterbox>
          <span>Supporting Blokchains:</span>
          <a href="">
            <img src={dexIcon020} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon021} alt="" />{' '}
          </a>
          <a href="">
            <img src={dexIcon025} alt="" />{' '}
          </a>
          <a href=""  className="disable">
            <img src={dexIcon022} alt="" />{' '}
          </a>
          <a href=""  className="disable">
            <img src={dexIcon023} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon024} alt="" />{' '}
          </a>
         
          <a href="" className="disable">
            <img src={dexIcon026} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon027} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon028} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon029} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon030} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon031} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon032} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon033} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon034} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon035} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon036} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon037} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon038} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon039} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon040} alt="" />{' '}
          </a>
          <a href="" className="disable">
            <img src={dexIcon041} alt="" />{' '}
          </a>
        </HeadCenterbox>
      </HeaderContainer>
    </>
  )
}
