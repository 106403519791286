import React, { useState } from 'react'
import styled from 'styled-components'
// import { CardProps, Text } from 'rebass'
// import { Box } from 'rebass/styled-components'
import { Collapse } from 'react-collapse'

const MainDropDown = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  margin-bottom: 20px;
`
const DropDownAnchor = styled.a`
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.headerImageBg};
  color: ${({ theme }) => theme.ifoText1};
  border-radius: 10px;
  padding: 16px 16px;
  /* color: #fff; */
  font-weight: 700;
  font-size: 14px;
  display: block;
  text-align: left;
  text-decoration: none;
  &:after {
    border-width: 0 8px 8px 0;
    border-color: transparent transparent ${({ theme }) => theme.ifoText1} transparent;
    right: 10px;
    bottom: 25px;
    transform: rotate(-45deg);
    content: '';
    /* border-width: 0 6px 6px 0; */
    border-style: solid;
    /* border-color: transparent transparent #fff transparent; */
    position: absolute;
  }
`

const DropDownBox = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  /* border: 1px solid #202020; */
  bottom: -2px;
  width: 100%;
  z-index: 11;
`

const Container = styled.div`
  /* height: 22px; */
  font-weight: 700;
  font-size: 16px;
  background-color: #1b1e25;
  border: 1px solid #1b1e25;
  padding: 10px;
  border-radius: 10px;
  display: block;
`

const Element = styled.a`
  color: #fff;
  border: 0px;
  border-bottom: 1px solid #202020;
  background-color: transparent;
  border-radius: 10px;
  display: block;
  padding: 12px 6px;
  background: transparent;
  font-weight: 700;
  font-size: 16px;

  text-align: left;
  background-color: #000000;
  :hover {
    color: #faba37;
  }
  :after {
    display: none;
  }
  /* border: 0px;
    color: #dadada; */
`

interface DropDownProps {
  options: any
  name: string
  changeSelected?: Function
}

export default function DropDown({ options, name, changeSelected }: DropDownProps) {
  const [isOpened, setIsOpened] = useState(false)
  // const [selectedValue, setSelectedValue] = useState(options[0])
  const listOptions = options.map(option => <Element onClick={() => changeSelected()}>{option.name}</Element>)

  return (
    <MainDropDown>
      <DropDownAnchor
        onClick={() => {
          setIsOpened(!isOpened)
        }}
      >
        {name}
      </DropDownAnchor>
      {options.length !== 0 ? (
        <DropDownBox>
          <Collapse isOpened={isOpened}>
            <Container>{listOptions}</Container>
          </Collapse>
        </DropDownBox>
      ) : (
        ''
      )}
    </MainDropDown>
  )
}
