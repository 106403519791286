import React, { PureComponent, useState } from 'react'
import { useActiveWeb3React } from '../../hooks'
// import Web3Status from '../Web3Status'
import { useWalletModalToggle } from '../../state/application/hooks'
import styled, { css, ThemeContext } from 'styled-components'
import IFOCounter from '../IFOCounter'
import { Collapse } from 'react-collapse'
import ToolTip from '../ToolTip'
import bnbICON from '../../assets/images/bnbICON.png'
import ethICON from '../../assets/images/ethICON.png'
import defaultImage from '../../assets/images/default.png'
import CoinICO from '../../assets/images/coinIco-04.png'
import CloseIMG from '../../assets/images/close-btn2.png'
import LShield from '../../assets/images/shildMark.png'
import RShield from '../../assets/images/qrIcon.png'
import ReactTooltip from 'react-tooltip'
import SimpleSlider from '../SimpleSlider'
import Media from '../../theme/media-breackpoint'
import Modal from '../Modal'

interface ConfirmTxProps {
  message
  isOpen: boolean
  setIsOpen: Function
  isFail: boolean
}
export default function ConfirmTxPopup({ message, isOpen, setIsOpen, isFail }: ConfirmTxProps) {
  return (
    <Modal isOpen={isOpen} onDismiss={() => {}} maxHeight={90}>
      <PPMBX>
        <PPContainer>
          {isFail ? (
            <PPTitle03>
              <i className="fas fa-times-circle"></i>
              {message}
            </PPTitle03>
          ) : (
            <PPTitle02>
              <i style={{ fontSize: '50px' }} className="fas fa-check-circle"></i>
              {message}
            </PPTitle02>
          )}
          <ClosBTN02 onClick={() => setIsOpen(false)}>
            <i></i>
          </ClosBTN02>
        </PPContainer>
      </PPMBX>
    </Modal>
  )
}
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  .md-checkbox {
    margin-top: 0px;
  }
`
const ClosBTN02 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  outline: none;
  background-color: #000;
  border-radius: 0 0 0 10px;
  transition: 400ms ease-in-out;
  i {
    background: url(${CloseIMG}) 50% 50% no-repeat;
    width: 22px;
    height: 22px;
    transition: 400ms ease-in-out;
  }
  &:hover i {
    transform: rotate(180deg);
  }
`
const PPFrmMBX = styled(FlexDiv)`
  flex-wrap: nowrap;
  max-width: 760px;
  margin: 50px auto 0 auto;
  ${Media.xs} {
    flex-wrap: wrap;
  }
`
const PPFrmBX01 = styled(FlexDiv)`
  justify-content: flex-start;
  width: 35%;
  font-size: 21px;
  font-weight: 700;
  color: white;

  ${Media.sm} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${Media.xs} {
    width: calc(100% - 15px);
    margin-bottom: 10px;
  }
`
const PPFrmBX02 = styled(FlexDiv)`
  justify-content: flex-start;
  width: 65%;
  ${Media.xs} {
    width: calc(100% - 15px);
    margin-bottom: 10px;
  }
`
const PPFrmInptBX = styled(FlexDiv)`
  justify-content: flex-start;
  border: 2px solid #545861;
  border-radius: 10px;
  padding: 10px 90px 10px 85px;
  height: 61px;
  position: relative;
  ${Media.xs} {
    padding: 10px 80px 10px 42px;
  }

  input {
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-size: 24px;
    font-weight: 700;
  }
  i.leftShild {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #545861;
    width: 66px;
    border-radius: 9px 0 0 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${Media.xs} {
      width: 36px;
    }
  }
  i.rightShild {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 90px;
    border-radius: 9px 0 0 9px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    font-style: normal;
    font-size: 11px;
    color: #8e9195;
    line-height: 20px;
    padding-right: 10px;
    img {
      margin-bottom: 2px;
    }
  }
`
const PPMBX = styled(FlexDiv)`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  ${Media.sm} {
    overflow-y: auto;
    top: auto;
  }
`
const PPContainer = styled(FlexDiv)`
  max-width: 1000px;
  margin: 0 auto;
  padding: 44px;
  background-color: #16191e;
  border: 2px solid #000000;
  border-radius: 10px;
  position: relative;
  ${Media.xs} {
    padding: 44px 20px;
  }
`
const PPTitle01 = styled(FlexDiv)`
  font: 700 36px/42px 'Kanit', arial;
  text-align: center;
  color: #fff;
  margin: 40px 0 15px 0;
`
const PPTitle02 = styled(FlexDiv)`
  font: 700 36px/42px 'Kanit', arial;
  text-align: center;
  color: #00f02b;
  margin: 40px 0 15px 0;
  .fas {
    margin-right: 8px;
  }
`
const PPTitle03 = styled(PPTitle02)`
  color: #c32b2d;
`
