import React, { useState } from 'react'
import styled from 'styled-components'
import { Collapse } from 'react-collapse'
import IFOCard from '../IFOCard'

const MoreViewBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 60px 0 30px 0;
`

const IFOBTN02 = styled.a`
  font-size: 18px;
  font-weight: 700;
  margin: 0 auto;
  /* margin-left: auto; */
  color: ${({ theme }) => theme.ifoText1};
  /* font-size: 14px;
    font-weight: 700; */
`
const IFOView = styled.div`
  display: flex;
  /* overflow: hidden; */
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -16px;
  justify-content: flex-start;
`
const SpanTxn01 = styled.span`
  display: block;
`

interface ComingSoonIFOProps {
  data
  balance?: string
  viewType?: number
  price?: number
}
export default function ComingSoonIFO({ data, balance, viewType, price }: ComingSoonIFOProps) {
  const [isOpened, setIsOpened] = useState(false)
  // const [details, setDetails] = useState("Details")
  const [detailsClass, setDetailsClass] = useState('fas fa-chevron-down')
  const todayDate = new Date().getTime() / 1000
  return (
    <div>
      <MoreViewBar>
        <IFOBTN02
          onClick={() => {
            setIsOpened(!isOpened)
            if (isOpened === true) {
              setDetailsClass('fas fa-chevron-down')
            } else {
              setDetailsClass('fas fa-chevron-up')
            }
          }}
        >
          <SpanTxn01>
            Coming Soon PDO <i className={detailsClass}></i>
          </SpanTxn01>
        </IFOBTN02>
      </MoreViewBar>
      <Collapse isOpened={isOpened}>
        <>
          {viewType === 1 ? (
            <IFOView>
              {data.map(d =>
                d.startTime > todayDate ? (
                  <IFOCard key={d.address} data={d} balance={balance} viewType={1} price={price} />
                ) : null
              )}
            </IFOView>
          ) : (
            <ListViewBox>
              <MainTable width="100%" cellSpacing={0} cellPadding={16}>
                <TableBody>
                  <HeaderTR>
                    <HeaderTH scope="col">PDO name</HeaderTH>
                    <HeaderTH scope="col">PDO Type</HeaderTH>
                    <HeaderTH scope="col">PDO Progress</HeaderTH>
                    <HeaderTH scope="col">PDO Status</HeaderTH>
                    <HeaderTH scope="col">&nbsp;</HeaderTH>
                    <HeaderTH scope="col">&nbsp;</HeaderTH>
                  </HeaderTR>
                  {data.map(d =>
                    d.startTime > todayDate ? (
                      <IFOCard key={d.address} data={d} balance={balance} viewType={2} price={price} />
                    ) : null
                  )}
                </TableBody>
              </MainTable>
            </ListViewBox>
          )}
        </>
      </Collapse>
    </div>
  )
}

/////////////////////ListViewBox//////////////////////////////

const ListViewBox = styled.div`
  display: block;
  margin: 35px 0 15px 0;
`

const MainTable = styled.table`
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: ${({ theme }) => theme.bg6};
`

const HeaderTR = styled.tr`
  border-bottom: 1px solid #393d46;
`

const HeaderTH = styled.th`
  border-top: 2px solid #393d46;
  border-bottom: 2px solid #393d46;
  vertical-align: middle;
  text-align: left;
  color: ${({ theme }) => theme.ifoText5} !important;
`
const TableBody = styled.tbody``

////////////////////////////////////////////////////////////////////
