import { Interface } from '@ethersproject/abi'
import { ChainId } from '@bscswap/sdk'
import V1_EXCHANGE_ABI from './v1_exchange.json'
import V1_FACTORY_ABI from './v1_factory.json'

const V1_FACTORY_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95',
  [ChainId.ROPSTEN]: '0x9c83dCE8CA20E9aAF9D3efc003b2ea62aBC08351',
  [ChainId.RINKEBY]: '0xf5D915570BC477f9B8D6C0E980aA81757A3AaC36',
  [ChainId.GÖRLI]: '0x6Ce570d02D73d4c384b46135E87f8C592A8c86dA',
  [ChainId.KOVAN]: '0xD3E51Ef092B2845f10401a0159B2B96e8B6c3D30',
  [ChainId.BSC_MAINNET]: '0xCe8fd65646F2a2a897755A1188C04aCe94D2B8D0',
  [ChainId.BSC_TESTNET]: '0xCe8fd65646F2a2a897755A1188C04aCe94D2B8D0',
  [ChainId.POLYGON_MAINNET]: '0x03183E867B15F25d6838A27c3743fC0D36B2D40f',
  [ChainId.POLYGON_TESTNET]: '0x75CeA8439e23269f79DB36caB245F0d6429bCe36',
  [ChainId.ARBITRUM_ONE]:''
}

const V1_FACTORY_INTERFACE = new Interface(V1_FACTORY_ABI)
const V1_EXCHANGE_INTERFACE = new Interface(V1_EXCHANGE_ABI)

export { V1_FACTORY_ADDRESSES, V1_FACTORY_INTERFACE, V1_FACTORY_ABI, V1_EXCHANGE_INTERFACE, V1_EXCHANGE_ABI }
