import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Fire from '../../assets/images/fire.png'
import { IFOLaunchTabProps } from '../../pages/IFOLaunch'
import ToolTip from '../ToolTip'
import InputRange from 'react-input-range'

// import { useCurrency } from '../../hooks/Tokens'

import Media from '../../theme/media-breackpoint'
import TextBox from '../TextBox'

export default function IFOLaunchTab2({ Value, setValue, moveToTab, factoryValues }: IFOLaunchTabProps) {
  const [dumperShieldRatio, SetDumperShieldRatio] = useState<number>(0)
  const [cashOutRatio, SetCashOutRatio] = useState<number>(0)
  const [poolRatio, SetPoolRatio] = useState<number>(100)
  const [Licenses, SetLicenses] = useState(null)

  const handleIDOMethod = value => {
    console.log(value)
    setValue(values => ({
      ...values,
      pdoFee: value
    }))
  }
  const handleDepositStructure = event => {
    event.persist()
    setValue(values => ({
      ...values,
      depositStructure: event.target.Value
    }))
  }

  useEffect(() => {
    if (window['isLicenses']) SetLicenses(window['Licenses'])
  })

  // useEffect(()=>{

  //     // if(dumperShieldRatio+poolRatio+cashOutRatio > 100){
  //     //     SetDumperShieldRatio(33)
  //     //     SetCashOutRatio(33)
  //     //     SetPoolRatio(34);
  //     // }

  //     // if(dumperShieldRatio+poolRatio+cashOutRatio < 100){
  //     //     SetDumperShieldRatio(33)
  //     //     SetCashOutRatio(33)
  //     //     SetPoolRatio(34);
  //     // }
  // },[dumperShieldRatio,poolRatio,cashOutRatio])

  const setCashRatio = event => {
    console.log(event)
    if (event === '') {
      SetCashOutRatio(0)
    } else if (event > 100) {
      SetCashOutRatio(100)
      event = 100
    } else if (event < 0) {
      SetCashOutRatio(0)
      event = 0
    } else SetCashOutRatio(Number(event))

    if (event === 100) {
      SetPoolRatio(0)
      SetDumperShieldRatio(0)
    } else if (event > cashOutRatio) {
      let diffrence = Number(event) - Number(cashOutRatio)
      if (diffrence > dumperShieldRatio) {
        diffrence -= Number(dumperShieldRatio)
        SetDumperShieldRatio(0)
        SetPoolRatio(Number(Number(poolRatio) - Number(diffrence)))
      } else {
        SetDumperShieldRatio(Number(dumperShieldRatio - diffrence))
      }
    } else if (event < cashOutRatio) {
      const diffrence = Number(cashOutRatio) - Number(event)
      SetDumperShieldRatio(Number(Number(dumperShieldRatio) + Number(diffrence)))
    }
  }

  const setDsRatio = event => {
    if (event === '') {
      SetDumperShieldRatio(0)
    } else if (event > 100) {
      SetDumperShieldRatio(100)
      event = 100
    } else if (event < 0) {
      SetDumperShieldRatio(0)
      event = 0
    } else SetDumperShieldRatio(Number(event))

    if (event === 100) {
      SetPoolRatio(0)
      SetCashOutRatio(0)
    } else if (event > dumperShieldRatio) {
      let diffrence = Number(event) - Number(dumperShieldRatio)
      if (diffrence > poolRatio) {
        diffrence -= Number(poolRatio)
        SetPoolRatio(0)
        SetCashOutRatio(Number(cashOutRatio - diffrence))
      } else {
        SetPoolRatio(Number(Number(poolRatio) - Number(diffrence)))
      }
    } else if (event < dumperShieldRatio) {
      const diffrence = Number(dumperShieldRatio) - Number(event)
      SetPoolRatio(Number(poolRatio + diffrence))
    }
  }

  const setPoolRatio = event => {
    if (event === '') {
      SetPoolRatio(0)
    } else if (event > 100) {
      SetPoolRatio(100)
      event = 100
    } else if (event < 0) {
      SetPoolRatio(0)
      event = 0
    } else SetPoolRatio(Number(event))

    if (event === 100) {
      SetDumperShieldRatio(0)
      SetCashOutRatio(0)
    } else if (event > poolRatio) {
      let diffrence = Number(event) - Number(poolRatio)
      if (diffrence > poolRatio) {
        diffrence -= Number(cashOutRatio)
        SetCashOutRatio(0)
        SetDumperShieldRatio(Number(Number(dumperShieldRatio) - Number(diffrence)))
      } else {
        setCashRatio(Number(Number(cashOutRatio) - Number(diffrence)))
      }
    } else if (event < poolRatio) {
      const diffrence = Number(poolRatio) - Number(event)
      SetCashOutRatio(Number(Number(cashOutRatio) + Number(diffrence)))
    }
  }

  const fireMoveToTab = () => {
    Value.investInfo.dumperShieldRatio = dumperShieldRatio
    Value.investInfo.cashOutRatio = cashOutRatio
    setValue(values => ({
      ...values
    }))
    moveToTab()
  }

  return (
    <JwbBonusFMTitle02>
      {Licenses !== null && (
        <>
          Select your PDO creation fee structure
          <BinaceSwaphelpcircle>
            <Helpcircle></Helpcircle>
          </BinaceSwaphelpcircle>
          <RadioWrap style={{ textAlign: 'center', paddingLeft: '100px' }}>
            {Licenses.amountUsd.map((value, i) => (
              <div className="md-radio" key={`idomethod-${i}`}>
                <Input
                  type="radio"
                  id={`method-${i}`}
                  name="idomethod"
                  value={value}
                  onClick={() => handleIDOMethod(value)}
                />
                <Label htmlFor={`method-${i}`}>
                  {' '}
                  ${value} + {Licenses.fee[i]}% fundraising fee
                </Label>
              </div>
            ))}
            {/* {factoryValues.freeForLimitedTime === 1 ? (
                            <div className="md-radio">
                                <Input type="radio" id="method4" name="idomethod" value="4" onChange={handleIDOMethod} />
                                <Label htmlFor="method4" style={{ color: '#00f02b' }}>Zero fees for pools with Native Token </Label>
                            </div>
                        ) : null} */}
          </RadioWrap>
          <JwbBonusFMTitle03>
            <Circle></Circle> The fund raised deposits to the same wallet you create your PDO with
          </JwbBonusFMTitle03>
          <p style={{ paddingTop: '30px' }}>
            Funds distribution{' '}
            <ToolTip
              tip={
                'Percent of fundraising that split between cash out to the team/project, liquidity pool and investors on the dumper shield.'
              }
              id={'m21'}
            />{' '}
          </p>
        </>
      )}

      <DragorMBX>
        <DragorSBX01 className={Number(cashOutRatio) === 0 ? 'disabled' : ''}>
          <div
            className={`dragorInput ${Number(cashOutRatio) > 99 ? 'above99' : cashOutRatio < 10 ? 'below10' : ''}`}
            style={{ paddingTop: '0' }}
          >
            <InputRange
              maxValue={100}
              minValue={0}
              value={cashOutRatio}
              formatLabel={value => `${value}%`}
              onChange={v => {}}
            />
          </div>
          <div className="dragorTItle01">
            Cash out
            <ToolTip tip={'Fund Goes to directly who created pdo'} id={'m21'} />
            <span>
              {' '}
              <InputRatio>
                <TextBox value={cashOutRatio} onUserInput={setCashRatio} placeholder=""></TextBox>
              </InputRatio>{' '}
            </span>
          </div>
        </DragorSBX01>

        <DragorSBX01 className={Number(dumperShieldRatio) === 0 ? 'disabled' : ''}>
          <div
            className={`dragorInput ${
              Number(dumperShieldRatio) > 99 ? 'above99' : dumperShieldRatio < 10 ? 'below10' : ''
            }`}
            style={{ paddingTop: '0' }}
          >
            <InputRange
              maxValue={100}
              minValue={0}
              value={dumperShieldRatio}
              formatLabel={value => `${value}%`}
              onChange={v => {}}
            />
          </div>
          <div className="dragorTItle01">
            Dumper shield{' '}
            <ToolTip
              tip={
                'Funds go to the Dumper Shield Gateway to buy tokens from pending investors. If there are no tokens pending on the Gateway, funds are distributed accordingly to your other selections (cash out & liquidity pool)'
              }
              id={'m21'}
            />
            <span>
              {' '}
              <InputRatio>
                <TextBox value={dumperShieldRatio} onUserInput={setDsRatio} placeholder=""></TextBox>
              </InputRatio>{' '}
            </span>
          </div>
        </DragorSBX01>

        <DragorSBX01 className={Number(poolRatio) === 0 ? 'disabled' : ''}>
          <div
            className={`dragorInput ${Number(poolRatio) > 99 ? 'above99' : poolRatio < 10 ? 'below10' : ''}`}
            style={{ paddingTop: '0' }}
          >
            <InputRange
              maxValue={100}
              minValue={0}
              value={poolRatio}
              formatLabel={value => `${value}%`}
              onChange={v => {}}
            />
          </div>
          <div className="dragorTItle01">
            Liquidity pool <ToolTip tip={'Fund Goes Amm pool and lp token created'} id={'m21'} />
            <span>
              {' '}
              <InputRatio>
                <TextBox value={poolRatio} onUserInput={setPoolRatio} placeholder=""></TextBox>
              </InputRatio>{' '}
            </span>
          </div>
        </DragorSBX01>
      </DragorMBX>

      <JwbCBTNBar02>
        <JwbConnectBTN onClick={() => fireMoveToTab()}>
          {/* <Image src= {Fire}/> */} DEPLOY PDO SMART CONTRACT
        </JwbConnectBTN>
      </JwbCBTNBar02>
    </JwbBonusFMTitle02>
  )
}

const InputRatio = styled.div`
  width: 70px;
  margin: auto;
`
const Image = styled.img``
const JwbBonusFMTitle02 = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin: 60px 0 10px 0;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.ifoText1};
`
const BinaceSwaphelpcircle = styled.i`
  font-size: 13px;
  position: relative;
  top: -11px;
  width: auto !important;
`
const Helpcircle = styled.i`
  font-size: 12px;
  font-weight: 900;
  font-family: 'Font Awesome 5 Free';
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
`
const RadioWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -30px 0 -30px;
  padding-top: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) and (min-width: 320px) {
    margin: 0;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 10px !important;
    text-align: left !important;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    margin: 0;
  }
`
const div = styled.div`
  padding: 0 4px;
`

const Input = styled.input`
  border-radius: 4px;
`
const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.ifoText1};
  padding-left: 27px;
  line-height: 21px;

  &:before {
    left: 0 !important;
    top: 0 !important;
    width: 20px !important;
    height: 20px !important;
    border: 2px solid ${({ theme }) => theme.ifoText1} !important;
    background: rgba(255, 255, 255, 0.05);
  }
  &:after {
    background: #00f02b !important;
  }
`
const JwbBonusFMTitle03 = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin: 20px 0 10px 0;
  text-align: center;
  width: 100%;
  color: #c32b1c;
  @media (max-width: 500px) and (min-width: 320px) {
    line-height: 24px;
    padding: 0 10px;
  }
`
const Circle = styled.i`
  font-weight: 900;
  font-family: 'Font Awesome 5 Free';
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;

  &:before {
    content: '\f05a';
  }
`
const JwbCBTNBar02 = styled.div`
  padding: 20px 30px;
  text-align: center;

  ${Media.xs} {
    padding: 20px 0px;
  }
`
const JwbConnectBTN = styled.a`
  background-color: #00f02b;
  width: 100%;
  max-width: 670px;
  padding: 27px 50px;
  display: inline-block;
  font: 700 30px/40px 'IBM Plex Mono', arial, sans-serif;
  color: #5d6168;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;

  ${Media.sm} {
    padding: 27px 20px;
    font: 700 26px/32px 'IBM Plex Mono', arial, sans-serif;
    letter-spacing: -1px;
  }
  ${Media.xs} {
    font: 700 18px/8px 'IBM Plex Mono', arial, sans-serif;
  }
`

const DragorMBX = styled.div`
  display: flex;
  width: 100%;
  max-width: 952px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
`
const DragorSBX01 = styled.div`
  display: flex;
  width: 33.33%;
  align-items: center;
  justify-content: center;
  padding: 22px;
  min-height: 450px;
  flex-direction: column;
  position: relative;
  padding-top: 0;

  &.disabled {
    .dragorInput .input-range__slider {
      // background-color:#373a40;
      background: linear-gradient(0deg, #3f4247, #282b33);
      border-color: #444850;
      -webkit-box-shadow: 0 0 15px 1px rgb(0 0 0 / 55%);
      box-shadow: 0 0 15px 1px rgb(0 0 0 / 55%);
    }
    .dragorInput .input-range__track {
      background-color: #1c1f27;
    }
    .dragorInput .input-range__track--active {
      background-color: #474d5a;
    }
    .dragorInput .input-range__label--max .input-range__label-container {
      color: ${({ theme }) => theme.headerImageBg};
    }
    .dragorInput .input-range__label--min .input-range__label-container {
      color: ${({ theme }) => theme.headerImageBg};
    }
    .dragorInput .input-range__label--value .input-range__label-container {
      color: #989ca4;
    }
    .dragorTItle01 {
      span {
        color: #5a5e67 !important;
      }
    }
  }

  .dragorInput {
    width: 334px;
    transform: rotate(-90deg);
    margin-left: 17px;
  }
  .dragorInput .input-range__label--value {
    transform: rotate(90deg) translate(5px, 42px);
  }
  .above99 .input-range__label--value {
    transform: rotate(90deg) translate(11px, 45px);
  }

  .below10 .input-range__label--value {
    transform: rotate(90deg) translate(2px, -5px);
  }
  .below10 .input-range__slider {
    margin-left: 0px;
  }
  .dragorInput .input-range__label--max {
    transform: rotate(90deg) translate(-14px, -17px);
  }
  .input-range__label-container {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
  }
  .dragorInput .input-range__label--min {
    transform: rotate(90deg) translate(10px, 10px);
  }
  .dragorTItle01 {
    position: absolute;
    bottom: -25px;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    color: #fff;

    span {
      width: 100%;
      text-align: center;
      padding-top: 6px;
      color: #00f02b;
      font-weight: 400;
      display: block;
    }
  }
`

// const DisableButton = styled.a`
//     background-color: ${({ theme }) => theme.ConnectButtonBG};
//     width: 100%;
//     max-width: 670px;
//     padding: 27px 50px;
//     display: inline-block;
//     font: normal 24px/40px 'Press Start 2P', arial, sans-serif;
//     color: #c32b1c;
//     text-align: center;
//     border-radius: 10px;
//     cursor: pointer;
// `
