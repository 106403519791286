import React, { useEffect, useState, useCallback } from 'react'
import { useActiveWeb3React } from '../../hooks'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useHistory } from 'react-router-dom'
import Media from '../../theme/media-breackpoint'

import { calculateGasMargin, isAddress } from '../../utils'
import styled /*, { ThemeContext }*/ from 'styled-components'
import { Collapse } from 'react-collapse'

import IFOLaunchTab1 from '../../components/IFOLaunchTab1'
import IFOLaunchTab2 from '../../components/IFOLaunchTab2'
// import IFOLaunchTab3 from '../../components/IFOLaunchTab3'

import { useFactoryPDOContract } from '../../hooks/useContract'
import { useAlert } from 'react-alert'
import TransactionConfirmationModal from '../../components/TransactionConfirmationModal'
import { API_BASEURL } from '../../constants'
import { ACTIVE_NETWORK } from '../../connectors'
import { ZERO_ADDRESS } from '../../constants/pdo'
import { BigNumber } from 'bignumber.js'
import { parseUnits } from '@ethersproject/units'

import { ethers } from 'ethers'
import axios from 'axios'
import { useCurrency } from '../../hooks/Tokens'

const api = axios.create({
  baseURL: API_BASEURL
})

const PERCENT_DENOMINATOR = 100

const getTokenAmountInWei = (amount: number, power: number | undefined) => {
  if (power === undefined) {
    return 0
  }
  return parseUnits(amount.toString(), power).toString()
}

const getPriceAmount = (amount: number) => {
  if (amount === 0) {
    return 0
  }
  return parseUnits(amount.toString(), 9).toString()
}

const getPercentAmount = (amount: number) => {
  if (amount === 0) {
    return 0
  }
  return new BigNumber(amount).multipliedBy(PERCENT_DENOMINATOR).toString()
}

export interface IFOLaunchTabProps {
  Value: {
    pdoType: number
    fairPdo: boolean
    startTime: Date
    endTime: Date
    totalSupply: number
    softCap: number
    isStaking: boolean
    isDumperSheild: boolean
    dumperShedilDay: Date
    pdoFee: number
    poolInfo: {
      dexAddress: string
      pairedToken: string
      offeringToken: string
      lpAddress: string
      supplyToDistribute: number
      rewardsPart: number
      apy: number
      period: number
    }
    investInfo: {
      minimumKycInvestment: number
      limitType: number
      minInvest: number
      maxInvest: number
      liquidityProtection: number
      dumperShieldRatio: number
      cashOutRatio: number
      isKyc: boolean
    }
    // idoType: string,
    // offeringToken: string,
    // startTime: Date,
    // endTime: Date,
    // idoFeeMethod: string,
    // isPauseLiquidity: boolean,
    // isStaking: boolean,
    // apy: string,
    // period: string,
    // kyc: boolean,
    // minimumInvestment: string,
    // supplyToDistribute: string,
    // softCap: string,
    // rewardsPart: string,
    // depositStructure: string,
    // routerAddress: string
  }
  setValue: (Value) => void
  moveToTab: () => void
  factoryValues?: {
    initialFee0: number
    initialFee50: number
    minimumLiquidityRequired: number
    freeForLimitedTime: number
  }
}

export default function IFOLaunch(props) {
  const { account, chainId } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected
  const history = useHistory()
  const alert = useAlert()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [startMark, setStartMark] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [refferAddress, setRefferAddress] = useState<string>('0x0000000000000000000000000000000000000000')

  const [initialFee0, setInitialFee0] = useState<number>(0)
  const [initialFee50, setInitialFee50] = useState<number>(0)
  const [minimumLiquidityRequired, setMinimumLiquidityRequired] = useState<number>(0)
  const [freeForLimitedTime, setFreeForLimitedTime] = useState<number>(0)
  const [nativePrice, setNativePrice] = useState<number>(0)

  const pdoFactory = useFactoryPDOContract()

  useEffect(() => {
    api.get(`/getNativePrice/${chainId}`).then(response => {
      setNativePrice(response.data.data)
    })
  })
  useEffect(() => {
    if (props.match.params.refercode === undefined || props.match.params.refercode === 'undefined') return
    api.get(`/getReferral/${props.match.params.refercode}`).then(response => {
      setRefferAddress(response.data.data.address)
    })
  }, [props.match.params.refercode])

  useEffect(() => {
    // api.get(`/getfactory`).then(response => {
    //     // setFactoryData(response.data.data)
    //     // console.log(factoryData)
    //     const data = response.data.data[0]
    //     setInitialFee0(data["initialFee0"])
    //     setInitialFee50(data["initialFee50"])
    //     setMinimumLiquidityRequired(data["minimumLiquidityRequired"])
    //     setFreeForLimitedTime(data["freeForLimitedTime"])
    //     setBnbPrice(data["bnbPrice"])
    // })
  }, [account, chainId])

  // const [hash, setHash] = useState('')
  const start = new Date()
  const end = new Date()
  end.setDate(end.getDate() + 1)

  const [values, setValues] = useState({
    pdoType: 0,
    fairPdo: false,
    startTime: start,
    endTime: end,
    totalSupply: 0,
    softCap: 0,
    isStaking: false,
    isDumperSheild: false,
    dumperShedilDay: start,
    pdoFee: 0,
    poolInfo: {
      dexAddress: '',
      pairedToken: '',
      offeringToken: '',
      lpAddress: '',
      supplyToDistribute: 0,
      rewardsPart: 0,
      apy: 0,
      period: 0
    },
    investInfo: {
      minimumKycInvestment: 0,
      limitType: 0,
      minInvest: 0,
      maxInvest: 0,
      liquidityProtection: 0,
      dumperShieldRatio: 0,
      cashOutRatio: 0,
      isKyc: false,
      refferAddress: refferAddress
    }
  })

  const offeringToken = useCurrency(values.poolInfo.offeringToken)
  const [isOpenedTab1, setIsOpenedTab1] = useState(true)
  const [isOpenedTab2, setIsOpenedTab2] = useState(false)
  // const [isOpenedTab3, setIsOpenedTab3] = useState(false)
  // const [address, setAddress] = useState('')
  // const [tokens, setTokens] = useState('')
  const validation = (): boolean | undefined => {
    // # CHAINID_UPDATE #
    // const isBinance = chainId === 97
    if (!ACTIVE_NETWORK.includes(chainId)) {
      alert.show('Please connect with Supported chain')
      return false
    }
    if (values.totalSupply === 0) {
      alert.show('Please enter supply to distribute')
      return false
    }
    if (values.pdoType === 1) {
      if (values.softCap === 0) {
        alert.show('Please enter Soft cap')
        return false
      }
    }

    if (values.isStaking === true) {
      if (values.poolInfo.period === 0) {
        alert.show('Please enter period')
        return false
      }

      if (values.poolInfo.apy === 0) {
        alert.show('Please enter APY')
        return false
      }
      // if (validateDecimal() === false) {
      //     alert.show('Please enter APY upto two decimal places')
      //     return false
      // }
    }

    // if (values.kyc === true) {
    //     if (values.minimumInvestment === '0' || values.minimumInvestment === '') {
    //         alert.show('Please enter minimum amount of KYC/AML')
    //         return false
    //     }
    // }

    if (!isAddress(values.poolInfo.offeringToken)) {
      alert.show('Please enter valid token address')
      return false
    }
    return true
  }

  // const validateDecimal = function () {
  //     var t = values.poolInfo.apy;
  //     // console.log(t.includes('.'))
  //     // 0x43f590Af238Cd9343BBFd0aaF361A0a8DbA56127
  //     if (t.includes('.')) {
  //         const array = t.split('.')
  //         if (array[1].length > 2) {
  //             return false
  //         }
  //     }
  //     return true
  // }
  const validateOfferingAddress = async () => {
    // return true;
    if (values.pdoType === 1) {
      if (values.softCap === 0) {
        alert.show('Please enter Soft cap')
        return false
      }
    }
  }
  const moveToTab1 = () => {
    setIsOpenedTab1(true)
    setIsOpenedTab2(false)
    // setIsOpenedTab3(false)
  }
  const moveToTab2 = async () => {
    // showMessage()
    // console.log(values.startTime)
    if (validation()) {
      const valid = await validateOfferingAddress()
      if (valid === false) {
        alert.show('please enter valid token address')
        return
      }

      setIsOpenedTab1(false)
      setIsOpenedTab2(true)
      // setIsOpenedTab3(false)
    }
  }

  const toWei = (val: string): string => {
    const wei = ethers.utils.parseEther(val)
    // console.log(wei.toString())
    return wei.toString()
  }

  const deployContract = async () => {
    let onError = false
    if (pdoFactory !== null) {
      setShowConfirm(true)
      const sumbitValue = {
        pdoType: values.pdoType,
        fairPdo: values.fairPdo,
        startTime: Math.floor(values.startTime.getTime() / 1000),
        endTime: Math.floor(values.endTime.getTime() / 1000),
        totalSupply: getTokenAmountInWei(values.totalSupply, offeringToken?.decimals),
        softCap: getPriceAmount(values.softCap),
        isStaking: values.poolInfo.period > 0 ? true : false,
        isDumperSheild: values.isDumperSheild,
        poolInfo: {
          dexAddress: values.poolInfo.dexAddress,
          pairedToken: values.poolInfo.pairedToken,
          offeringToken: values.poolInfo.offeringToken,
          lpAddress: ZERO_ADDRESS,
          supplyToDistribute: 0,
          rewardsPart: 0,
          apy: getPercentAmount(values.poolInfo.apy),
          period: values.poolInfo.period * 86400
        },
        investInfo: {
          minimumKycInvestment: getPriceAmount(values.investInfo.minimumKycInvestment),
          limitType: values.investInfo.limitType,
          minInvest:
            values.investInfo.limitType === 1
              ? getTokenAmountInWei(values.investInfo.minInvest, offeringToken?.decimals)
              : values.investInfo.limitType === 2
              ? getPriceAmount(values.investInfo.minInvest)
              : 0,
          maxInvest:
            values.investInfo.limitType === 1
              ? getTokenAmountInWei(values.investInfo.maxInvest, offeringToken?.decimals)
              : values.investInfo.limitType === 2
              ? getPriceAmount(values.investInfo.maxInvest)
              : 0,
          liquidityProtection: values.investInfo.liquidityProtection,
          dumperShieldRatio: getPercentAmount(values.investInfo.dumperShieldRatio),
          cashOutRatio: getPercentAmount(values.investInfo.cashOutRatio),
          isKyc: values.investInfo.isKyc,
          refferAddress: refferAddress
        }
      }

      let amountSent = Number(values.pdoFee / nativePrice)

      if (Number(amountSent) > 0) amountSent = amountSent + 0.02

      console.log('amountSent', amountSent)
      let lNumber = 1

      if (window['isLicenses']) {
        lNumber = window['Licenses']['licenseNumber']
      }

      try {
        const dumperShedilDay = values.isDumperSheild ? Math.floor(values.dumperShedilDay.getTime() / 1000) : 0

        console.log(sumbitValue,dumperShedilDay)
        const estimatedGas = await pdoFactory.estimateGas.LaunchPdo(lNumber, sumbitValue, dumperShedilDay, {
          value: getTokenAmountInWei(amountSent, 18)
        })

        const txResponse = await pdoFactory
          .LaunchPdo(lNumber, sumbitValue, dumperShedilDay, {
            gasLimit: calculateGasMargin(estimatedGas),
            value: getTokenAmountInWei(amountSent, 18)
          })
          .catch(() => {
            onError = true
            setShowConfirm(false)
          })
        if (onError === false) {
          setTxHash(txResponse.hash)
          window.tap('conversion',txResponse.hash);
          const txReceipt = await txResponse.wait()
          if (txReceipt.status === 1) {
            setTimeout(() => {
              setShowConfirm(false)
              history.push('/list')
            }, 3000)
          } else {
            alert.show('Some error occur in creating pdo')
            setShowConfirm(false)
          }
        }
      } catch (error) {
        //console.log(error.message)
        onError = true
        setShowConfirm(false)
        console.log(error)
        // alert.show(error.data.message)
      }
      // if (onError === false) {
      //     setTxHash(txResponse.hash)
      //     await txResponse.wait()
      //     setStartMark(true);
      //     setTimeout(() => {
      //         setShowConfirm(false)
      //         history.push('/list')
      //     }, 3000)
      // }
    }
  }
  const moveToTab3 = async () => {
    await deployContract()
  }
  const changeTab = (tab: number) => {
    if (tab === 1) {
      moveToTab1()
    } else if (tab === 2) {
      moveToTab2()
    } else {
      moveToTab3()
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
  }, [])

  return (
    <Section id="startSection">
      <TransactionConfirmationModal
        isOpen={showConfirm}
        startMark={startMark}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={txHash === '' ? true : false}
        hash={txHash}
        content={() => <></>}
        pendingText="Waiting for Confirmation"
      />
      <GridContainer>
        <BinanceTitle01>Launch Your PDO</BinanceTitle01>
        <LaunchInfo>
          <IfoInfoTitle002>
            <BxNo>1</BxNo>
            <Span>
              Project offers tokens for sale with a staking period and APY reward and sets how much of the fundraising
              will go to operations vs liquidity pool.
            </Span>
          </IfoInfoTitle002>
          <IfoInfoTitle002>
            <BxNo>2</BxNo>
            <Span>
              Token offering price is automatically calculated from the liquidity pool, allowing the public to purchase
              with zero slippage at the current market prices.
            </Span>
          </IfoInfoTitle002>
          <IfoInfoTitle002>
            <BxNo>3</BxNo>
            <Span>Funds boost the token price and liquidity in pools.</Span>
          </IfoInfoTitle002>
        </LaunchInfo>
        <JwbTabLink>
          <JwbTabLinka onClick={() => changeTab(1)} active={isOpenedTab1}>
            Step 1: Set up PDO
          </JwbTabLinka>
          <JwbTabLinka onClick={() => changeTab(2)} active={isOpenedTab2}>
            Step 2: Deploy smart contact
          </JwbTabLinka>
          {/* <JwbTabLinka onClick= {() => changeTab(3)} active={isOpenedTab3}>
                Step 3: Thankyou 
            </JwbTabLinka>       */}
        </JwbTabLink>
        {ACTIVE_NETWORK.includes(chainId) && account ? (
          <React.Fragment>
            <Collapse isOpened={isOpenedTab1}>
              <IFOLaunchTab1
                Value={values}
                setValue={setValues}
                moveToTab={moveToTab2}
                factoryValues={{
                  initialFee0: initialFee0,
                  initialFee50: initialFee50,
                  minimumLiquidityRequired: minimumLiquidityRequired,
                  freeForLimitedTime: freeForLimitedTime
                }}
              ></IFOLaunchTab1>
            </Collapse>
            {isOpenedTab2 && (
              <Collapse isOpened={isOpenedTab2}>
                <IFOLaunchTab2
                  Value={values}
                  setValue={setValues}
                  moveToTab={moveToTab3}
                  factoryValues={{
                    initialFee0: initialFee0,
                    initialFee50: initialFee50,
                    minimumLiquidityRequired: minimumLiquidityRequired,
                    freeForLimitedTime: freeForLimitedTime
                  }}
                ></IFOLaunchTab2>
              </Collapse>
            )}
            {/* <Collapse isOpened={isOpenedTab3}> 
              <IFOLaunchTab3 address={address} tokens={tokens} ></IFOLaunchTab3>
          </Collapse> */}
          </React.Fragment>
        ) : (
          <>
            <ConnectBox>
              <ConnectButton onClick={toggleWalletModal}>Connect Wallet</ConnectButton>
            </ConnectBox>
          </>
        )}
      </GridContainer>
    </Section>
  )
}

const ConnectBox = styled.div`
  padding: 100px 30px;
  text-align: center;

  ${Media.xs} {
    padding: 100px 0;
  }
`
const ConnectButton = styled.a`
  background-color: ${({ theme }) => theme.ConnectButtonBG};
  display: inline-block;
  font: 700 30px/40px 'IBM Plex Mono', arial, sans-serif;
  /* background-color: #5d6168; */
  color: #fff;
  padding: 20px 50px;
  /* -webkit-box-shadow: inset 0 -4px 0 0 rgba(0,0,0,0.2); */
  /* box-shadow: inset 0 -4px 0 0 rgba(0,0,0,0.2); */
  text-align: center;
  border-radius: 10px;
  min-width: 660px;
  ${Media.sm} {
    min-width: 100%;
  }

  ${Media.xs} {
    padding: 20px;
    font: 700 24px/30px 'IBM Plex Mono', arial, sans-serif;
  }
`

const LaunchInfo = styled.section`
  display: flex;
  ${Media.xs} {
    flex-flow: column;
  }
`
const IfoInfoTitle002 = styled.div`
  position: relative;
  color: ${({ theme }) => theme.ifoText1};
  margin: 0 0 0 0;
  padding-left: 52px;
  padding-right: 35px;
  line-height: 22px;
  font-weight: 700;
  font-size: 18px;
  padding-top: 5px;
  /* min-height: 62px; */
  width: 100%;
  text-align: left;
  ${Media.xs} {
    padding-right: 5px;
    margin-bottom: 20px;
  }
  span {
    line-height: 24px;
  }
`
const BxNo = styled.i`
  font: 700 24px/33px 'Kanit', arial;
  color: #fff;
  background-color: #00f02b;
  position: absolute;
  min-width: 33px;
  text-align: center;
  margin: 0 0 45px 0;
  left: 0;
  top: 0px;
  padding: 0 6px;
`
const Span = styled.span`
  color: ${({ theme }) => theme.ifoText1};
  display: block;
  font-weight: 400;
  font-size: 14px;
  margin: 0px 0 0 0;
`

const Section = styled.section`
  width: 100%;
  display: flex;
  /* height: 100%; */
  min-height: calc(100vh - 100px);
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding-top: 160px;
  @media (max-width: 767px) {
    padding-top: 73px;
  }
`
const GridContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  ${Media.lg} {
    padding: 0 15px;
  }
`
const BinanceTitle01 = styled.div`
  color: ${({ theme }) => theme.ifoText1};
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 0 45px 0;
  font: 700 54px/55px 'Kanit', monospace, arial;

  span {
    font-size: 18px;
    color: #febb00;
  }

  @media (max-width: 500px) and (min-width: 320px) {
    font-size: 21px;
    padding-top: 30px;
    line-height: 35px;
    padding-left: 8px;
    padding-right: 8px;
  }
  @media (max-width: 767px) and (min-width: 501px) {
    font-size: 32px;
    padding-top: 60px;
    line-height: 35px;
    padding-left: 8px;
    padding-right: 8px;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    font-size: 30px;
    line-height: 45px;
    padding: 0 20px;
  }
  @media (max-width: 1199px) and (min-width: 992px) {
    font-size: 36px;
    line-height: 45px;
    padding: 0 80px;
  }
`
const JwbTabLink = styled.div`
  width: 100%;
  display: flex;
  font-weight: 700;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  margin: 60px 0 40px 0;
  flex-direction: row;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  @media (max-width: 500px) {
    margin: 20px 0 20px 0;
  }
`
const JwbTabLinka = styled.a<{ active: boolean }>`
  display: inline-block;
  width: 50%;
  text-align: center;
  /* color: #000; */
  border-bottom: 3px solid #000;
  line-height: 70px;
  font-weight: 700;
  font-size: 20px;

  color: ${({ active }) => (active ? '#00f02b' : ({ theme }) => theme.ifoText1)};
  border-color: ${({ active }) => (active ? '#00f02b' : '')};

  :hover {
    color: #00f02b;
    border-color: #00f02b;
  }
  @media (max-width: 1199px) and (min-width: 992px) {
    font-size: 16px;
  }

  @media (max-width: 991px) and (min-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    width: 100%;
    line-height: 56px;
    font-size: 16px;
  }
`
